/**
 * Re-formats the string from an underscored var to camel case (e.g. test_data_groups > testDataGroups)
 * Note this is a duplicated function from fetchReportData.ts - need to centralise
 * @param underscoredString
 */
export const underscoreToCamelCase = (underscoredString: string): string => {
  if (/_/.test(underscoredString)) {
    const split = underscoredString.split("_");

    underscoredString = split
      .map((word: string, i: number) =>
        i === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join("");
  }

  return underscoredString;
};
