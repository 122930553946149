// Core
import React, { ReactElement } from "react";

// Components
import { Breadcrumbs, GroupsList } from "@cpsq/ui-components";
import { Button } from "@cambridgeassessment/cambridge-ui";
import ManageCentre from "../../components/manageCentre/manageCentre";
import { Typography } from "@material-ui/core";

// Interfaces
import { Centre } from "@cpsq/ui-interfaces";
import { ErrorHttpResponse } from "../../interfaces/http-responses/error-http-response";

// Stylesheets
import "./centreSettings.scss";
import "./centreSettings-responsive.scss";

// Utils
import Api from "../../utils/api";
import { Session } from "@cpsq/auth-frontend";
import { isErrorHttpResponse } from "@cpsq/common-utils";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Modal from "react-modal";

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  activeTabName: string;
  centre: Centre | null;
  loading: boolean;
  manageableCentre: boolean;
  manageablePayment: boolean;
  deleteCentreModalOpen: boolean;
}

interface Tabs {
  [key: string]: {
    displayName: string;
    icon: IconName;
    display?: boolean;
  };
}

export class CentreSettings extends React.Component<Props, State> {
  centreId =
    Session.getSessionValue("selectedCentreId") ||
    Session.getSessionValue("centreId");
  searchInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.searchInput = React.createRef();

    this.state = {
      activeTabName: "manageCentre",
      centre: null,
      loading: false,
      manageableCentre: false,
      manageablePayment: false,
      deleteCentreModalOpen: false
    };

    this.handleGetCentre = this.handleGetCentre.bind(this);
    this.handleCentreFeatures = this.handleCentreFeatures.bind(this);
    this.redirectToCentres = this.redirectToCentres.bind(this);
    this.toggleDeleteCentreModalVisibility = this.toggleDeleteCentreModalVisibility.bind(
      this
    );
    this.deleteCentre = this.deleteCentre.bind(this);
  }

  componentDidMount(): void {
    if (this.centreId) {
      Api.getCentre(this.centreId, this.handleGetCentre);
    }
    Api.getCentreFeatures(this.handleCentreFeatures);
  }

  render(): ReactElement {
    const tabs: Tabs = {
      manageCentre: {
        displayName: "Manage centre",
        icon: "school",
        display: this.state.manageableCentre
      },
      managePayment: {
        displayName: "Manage payment",
        icon: "coins",
        display: this.state.manageablePayment
      },
      manageGroups: {
        displayName: "View groups",
        icon: "chalkboard-teacher",
        display: true
      }
    };

    return (
      <section className="centre-settings">
        {this.renderDeleteCentreModal()}
        {this.state.centre && (
          <>
            {this.renderHero(this.state.centre)}
            {this.renderTabsNavigation(tabs, this.state.activeTabName)}
            {this.renderTabsBody(
              tabs,
              this.state.activeTabName,
              this.state.centre
            )}
          </>
        )}
      </section>
    );
  }

  deleteCentre(): void {
    const { centre } = this.state;
    if (!centre || !centre.centreId) {
      return;
    }

    // TODO Add error handler & doublecheck constraints
    Api.deleteCentre(centre.centreId, this.handleDeleteCentre.bind(this));
  }

  clickTab(tabName: string): void {
    if (this.state.activeTabName === tabName) {
      return;
    }

    this.setState({
      activeTabName: tabName
    });
  }

  handleDeleteCentre(
    response: { message: "success" } | ErrorHttpResponse
  ): void {
    if (isErrorHttpResponse(response)) {
      return;
    }
    const session = Session.getSession();
    if (session) {
      session.selectedCentreName = "";
      session.selectedCentreId = "";

      sessionStorage.setItem("CASession", JSON.stringify(session));
    }

    this.props.history.push("/centres/");
  }

  handleGetCentre(centre: Centre): void {
    this.setState({
      centre
    });
  }

  handleCentreFeatures(features: {
    manageableCentre: boolean;
    manageablePayment: boolean;
  }): void {
    this.setState({
      manageableCentre: features.manageableCentre,
      manageablePayment: features.manageablePayment
    });
  }

  renderDeleteCentreModal(): ReactElement {
    return (
      <Modal
        ariaHideApp={false}
        className="manage-users-delete-user-modal"
        isOpen={this.state.deleteCentreModalOpen}
        onRequestClose={this.toggleDeleteCentreModalVisibility}
        contentLabel="Delete Centre Modal"
      >
        <div className="modal-header">
          <FontAwesomeIcon icon="trash" size="lg" />
        </div>
        <div className="modal-body">
          <Typography variant="subtitle1" gutterBottom className="delta bold">
            Delete this centre?
          </Typography>
          <Typography variant="body1">
            This centre will be permanently deleted.
          </Typography>
        </div>
        <div className="modal-footer">
          <Button
            color="default"
            variant="text"
            key="Cancel"
            onClick={this.toggleDeleteCentreModalVisibility}
            className="button"
            data-testid="Cancel"
          >
            Cancel
          </Button>
          <Button
            color="default"
            key="Delete"
            onClick={this.deleteCentre}
            className="button"
            data-testid="Delete"
          >
            Delete
          </Button>
        </div>
      </Modal>
    );
  }

  redirectToCentres(): void {
    this.props.history.push("/centres/");
  }

  renderHero(centre: Centre): ReactElement {
    return (
      <div className="hero breakout">
        <Breadcrumbs
          items={[
            <span key="back" className="back" onClick={this.redirectToCentres}>
              <FontAwesomeIcon icon="angle-left" size="lg" /> Back
            </span>
          ]}
        />
        <div className="centre-logo">
          <span className="alpha bold">
            {centre.name.slice(0, 1).toUpperCase()}
          </span>
        </div>
        <div className="centre-details">
          <Typography variant="h3" className="beta semibold">
            {centre.name} [{centre.centreId}]
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className="delta bold"
          >
            Centre settings
          </Typography>
        </div>
      </div>
    );
  }

  reroute(groupId: string): void {
    let targetUrl = window.location.origin;

    process.env.PUBLIC_URL
      ? (targetUrl += `/admin/group-dashboard/?groupId=${groupId}`)
      : (targetUrl += `/group-dashboard/?groupId=${groupId}`);

    window.location.href = targetUrl;
  }

  renderManagePayment(): ReactElement {
    return <div className="manage-payment">Manage payment</div>;
  }

  renderTabsBody(
    tabs: Tabs,
    activeTabName: string,
    centre: Centre
  ): ReactElement {
    return (
      <div className="tabs-body">
        {Object.keys(tabs).map(key => {
          return (
            <div key={key}>
              {activeTabName === key && (
                <>
                  {key === "manageCentre" && <ManageCentre centre={centre} />}
                  {key === "managePayment" && this.renderManagePayment()}
                  {key === "manageGroups" && (
                    <div>
                      <form className="groups-filter">
                        <input
                          type="text"
                          placeholder="Search for groups"
                          ref={this.searchInput}
                        />
                        <FontAwesomeIcon icon="search" />
                      </form>
                      <GroupsList
                        inputRef={this.searchInput}
                        clickGroup={this.reroute}
                        centreId={this.centreId}
                        getGroups={Api.getGroups}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderTabsNavigation(tabs: Tabs, activeTabName: string): ReactElement {
    //@ts-ignore
    const onProd: boolean = process.env.NODE_ENV === "prd";
    return (
      <div className="tabs-navigation">
        {Object.keys(tabs).map(key => {
          if (!tabs[key].display) {
            return null;
          }
          return (
            <Button
              key={key}
              color={activeTabName === key ? "primary" : "default"}
              disabled={key === "managePayment"}
              onClick={(): void => {
                this.clickTab(key);
              }}
              startIcon={<FontAwesomeIcon icon={tabs[key].icon} />}
              data-testid={tabs[key].displayName}
            >
              {tabs[key].displayName}
            </Button>
          );
        })}
        {this.state.manageableCentre && !onProd ? (
          <Button
            color="destructive"
            variant="text"
            key="cancel"
            onClick={this.toggleDeleteCentreModalVisibility}
            startIcon={<FontAwesomeIcon icon="trash" />}
            data-testid="Delete centre"
          >
            Delete centre
          </Button>
        ) : null}
      </div>
    );
  }

  toggleDeleteCentreModalVisibility(): void {
    this.setState({
      deleteCentreModalOpen: !this.state.deleteCentreModalOpen
    });
  }
}

export default withRouter(CentreSettings);
