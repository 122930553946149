// Core
import React, { ReactElement } from "react";
// Interfaces
import { Centre } from "@cpsq/ui-interfaces";
import { CentreReport } from "../../interfaces/centre-report";
// Utils
import { Session } from "@cpsq/auth-frontend";
import { getViewportSize } from "@cpsq/common-utils";
import { createMuiDatatableTheme } from "@cpsq/ui-components";
import { mainTheme, Button } from "@cambridgeassessment/cambridge-ui";
// Vendor
import { History } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MuiThemeProvider, Theme } from "@material-ui/core/styles";

// @ts-ignore
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
  MUISortOptions
} from "mui-datatables";

const getMuiTheme = (viewportSize: string): Theme => {
  const theme = createMuiDatatableTheme(viewportSize, {
    fixed: true,
    columns: 2
  });

  return {
    ...theme,
    typography: { ...mainTheme.typography },
    //@ts-ignore
    overrides: {
      ...theme.overrides,
      MuiButton: {
        sizeLarge: { minWidth: "120px" }
      }
    },
    palette: {
      ...theme.palette,
      //@ts-ignore
      primary: {
        main: "rgba(0,0,0,.37)",
        dark: "rgba(0,0,0,.37)"
      }
    }
  };
};

interface CentreWithPendingStatus extends Centre {
  pending?: boolean;
}

interface Props {
  centres: CentreWithPendingStatus[];
  centreReports: CentreReport[];
  history: History;
}

interface State {
  sortedColumn: MUISortOptions;
  viewportSize: string;
}

class CentresTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sortedColumn: {
        direction: "asc",
        name: "name"
      },
      viewportSize: getViewportSize(window.innerWidth)
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  render(): ReactElement {
    const options: MUIDataTableOptions = {
      selectToolbarPlacement: "none",
      download: false,
      filter: false,
      onColumnSortChange: this.sortColumn.bind(this),
      pagination: false,
      print: false,
      responsive: "vertical",
      sortOrder: this.state.sortedColumn,
      search: true,
      selectableRows: "none",
      viewColumns: false
    };

    return (
      <MuiThemeProvider theme={getMuiTheme(this.state.viewportSize)}>
        <MUIDataTable
          data={this.props.centres}
          columns={this.getColumns()}
          options={options}
          title=""
        />
      </MuiThemeProvider>
    );
  }

  clickView(centreName: string): void {
    const centre = this.getCentre(centreName);
    const session = Session.getSession();

    if (centre && session) {
      session.selectedCentreName = centre.name;
      session.selectedCentreId = centre.centreId;

      sessionStorage.setItem("CASession", JSON.stringify(session));

      this.props.history.push("/centre-settings/");
    }
  }

  getCentre = (name: string): CentreWithPendingStatus | undefined =>
    this.props.centres.find(c => c.name === name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): any[] {
    return [
      {
        name: "name",
        label: "Centre",
        options: {
          sortDirection:
            this.state.sortedColumn.name === "name"
              ? this.state.sortedColumn.direction
              : "none",
          setCellProps: (): Record<string, Record<string, string>> => ({
            style: {
              whiteSpace: "normal",
              wordBreak: "break-word"
            }
          })
        }
      },
      {
        name: "centreId",
        label: "Centre Number",
        options: {
          sortDirection:
            this.state.sortedColumn.name === "centreId"
              ? this.state.sortedColumn.direction
              : "none"
        }
      },
      {
        name: "defaultReportType",
        label: "Report type",
        options: {
          customBodyRender: this.renderReportTypeBody,
          sortDirection:
            this.state.sortedColumn.name === "defaultReportType"
              ? this.state.sortedColumn.direction
              : "none"
        }
      },
      {
        name: "view",
        label: " ",
        options: {
          empty: true,
          filter: false,
          sort: false,
          customBodyRender: this.renderViewColumnBody
        }
      }
    ];
  }

  handleWindowResize(): void {
    const viewportSize = getViewportSize(window.innerWidth);

    if (this.state.viewportSize === viewportSize) {
      return;
    }

    this.setState({
      viewportSize
    });
  }

  renderReportTypeBody = (value: string): ReactElement => {
    return (
      <>
        {this.props.centreReports
          ? this.props.centreReports.find(r => r.type === value)?.name
          : ""}
      </>
    );
  };

  renderStatusBody = (value: boolean | undefined): ReactElement => {
    let status;

    switch (value) {
      case true:
        status = "Pending";
        break;
      case false:
        status = "Active";
        break;
      default:
        status = "Loading...";
        break;
    }
    let pillClass = "default";

    if (!value) {
      pillClass = "success";
    }
    if (value === undefined) {
      pillClass = "warning";
    }
    return <span className={"pill " + pillClass}>{status}</span>;
  };

  renderViewColumnBody = (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    value: any,
    tableMeta: MUIDataTableMeta
  ): ReactElement => (
    <Button
      color="primary"
      variant="text"
      onClick={this.clickView.bind(this, tableMeta.rowData[0])}
      startIcon={<FontAwesomeIcon icon="eye" />}
      data-testid="View"
    >
      View
    </Button>
  );

  sortColumn(
    name: MUISortOptions["name"],
    direction: MUISortOptions["direction"]
  ): void {
    this.setState({
      sortedColumn: {
        direction,
        name
      }
    });
  }
}

export default CentresTable;
