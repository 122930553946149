import React, { ReactElement, Fragment, ReactNode } from "react";

// Stylesheets
import "./groupMultiSearch.scss";

// Components
import { Typography } from "@material-ui/core";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { IndicatorContainerProps } from "react-select";
import { ActionMeta, ValueType } from "react-select/src/types";

export interface Option {
  value: string;
  label: string;
}

interface Props {
  options?: Option[];
  defaultSelectedOptions: Option[];
  handleOptionChange: Function;
  optionsHeading?: string;
  selectArguments?: object;
}

interface State {
  selectedOptions: Option[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IndicatorsContainer = (_props: IndicatorContainerProps<any>): null =>
  null;

class GroupMultiSearch extends React.Component<Props, State> {
  state = {
    selectedOptions: []
  };
  defaultGroupIds: string[] = [];

  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.defaultSelectedOptions !== this.props.defaultSelectedOptions &&
      this.props.options &&
      this.props.options.length > 0
    ) {
      this.defaultGroupIds = this.props.defaultSelectedOptions.map(
        op => op.value
      );
      this.setState({ selectedOptions: this.props.defaultSelectedOptions });
    }
  }

  formatOptionLabel = (value: Option): ReactNode => (
    <div className="ca-dropdown-option" data-testid="dropdown-option">
      <div>{value.label}</div>
      <p className="options-heading">{this.props.optionsHeading}</p>
    </div>
  );

  onOptionSelect = (
    value: ValueType<Option>,
    _actionMeta: ActionMeta
  ): void => {
    this.setState(
      {
        selectedOptions: value ? [...value] : []
      },
      () => {
        this.props.handleOptionChange(this.state.selectedOptions);
      }
    );
  };

  onGroupRemove = (id: string): void => {
    if (this.defaultGroupIds.includes(id)) return;

    const updatedSelection = this.state.selectedOptions.filter(
      (group: Option) => group.value !== id
    );
    this.setState({ selectedOptions: updatedSelection }, () => {
      this.props.handleOptionChange(this.state.selectedOptions);
    });
  };

  render(): ReactElement {
    return (
      <Fragment>
        <div className="react-select-container">
          <div className="react-select-input">
            <FontAwesomeIcon icon="search" />
            <Select
              className="react-select"
              classNamePrefix="react-select"
              components={{
                IndicatorsContainer
              }}
              controlShouldRenderValue={false}
              data-testid="group-search"
              formatOptionLabel={this.formatOptionLabel}
              isMulti
              onChange={this.onOptionSelect}
              options={this.props.options}
              placeholder="Search for a group"
              value={this.state.selectedOptions}
              {...this.props.selectArguments}
            />
          </div>
        </div>
        {this.state.selectedOptions && this.state.selectedOptions.length > 0 && (
          <div className="breakout margin-top-sm">
            <div className="wrap">
              {this.props.optionsHeading && (
                <Typography variant="h5" className="options-heading bold">
                  {this.props.optionsHeading}
                </Typography>
              )}
              <ul className="selected-group-list margin-top-sm">
                {this.state.selectedOptions.map((option: Option) => {
                  return (
                    <li
                      className={`pill-selected-group ${
                        this.defaultGroupIds.includes(option.value)
                          ? "disabled"
                          : ""
                      }`}
                      key={`selected-group-${option.value}`}
                    >
                      <p>
                        {option.label}
                        <button
                          className="remove-btn"
                          onClick={(): void => this.onGroupRemove(option.value)}
                        >
                          <FontAwesomeIcon icon="times-circle" size="lg" />
                        </button>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default GroupMultiSearch;
