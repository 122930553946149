import { Group } from "@cpsq/ui-interfaces";

export const getRespondentIdsByStatus = (
  group: Group | null,
  status: string
): string[] => {
  if (!group) {
    return [];
  }

  const respondents =
    group[
      `${status}Respondents` as
        | "notSentRespondents"
        | "sendingRequestRespondents"
        | "sentRespondents"
        | "completedRespondents"
    ];

  if (respondents) {
    if (status === "completed") {
      return respondents.map(respondent => respondent.id);
    }

    return respondents.filter(r => r.email).map(respondent => respondent.id);
  }

  return [];
};
