// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import { FormProgressIndicator } from "@cpsq/ui-components";
import SingleCentreForm from "../../components/singleCentreForm/singleCentreForm";

// Interfaces
import { Centre } from "@cpsq/ui-interfaces";
import { CentreReport } from "../../interfaces/centre-report";

// Stylesheets
import "./newCentre.scss";

// Utils
import Api from "../../utils/api";
import { handleGetCentreReports } from "../../utils/centre-form-helpers";

// Vendor
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  history: History;
}

const NewCentre: FC<Props> = (props: Props): ReactElement => {
  // TODOS: create interface for handleCreateCentre response (ala AddUsersResponse in centreSettings), disable buttons once clicked
  const [activeFormStep, setActiveFormStep] = useState(1);
  const [centreNames, setCentreNames] = useState(getCentreNames(props.history));
  const [centreReport, setCentreReport] = useState({} as CentreReport);
  const [centreReportTypeOptions, setCentreReportTypeOptions] = useState({});
  const [centreReports, setCentreReports] = useState([] as CentreReport[]);
  const [formComplete, setFormComplete] = useState(false);
  const [formError] = useState("");
  const [isFormPristine, setIsFormPristine] = useState(true);
  const centreNameMinLength = 2;
  const totalFormSteps = 2;

  useEffect(() => {
    document.body.className = "new-centre";

    return (): void => {
      document.body.className = "";
    };
  });

  useEffect(() => {
    const handleGetCentres = (response: Centre[]): void => {
      setCentreNames(response.map(item => item.name.toLowerCase()));
    };

    if (!centreNames.length) {
      Api.getCentres(handleGetCentres);
    }
  }, [centreNames]);

  useEffect(() => {
    Api.getCentreReports((response: CentreReport[]) => {
      handleGetCentreReports(
        response,
        setCentreReports,
        setCentreReportTypeOptions
      );
    });
  }, []);

  const clickBack = (): void => {
    props.history.push("/centres/");
  };

  function getCentreNames(history: History): string[] {
    if (!history) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyLocationState: any = history.location.state;

    if (!historyLocationState || !historyLocationState.centreNames) {
      return [];
    }

    return historyLocationState.centreNames;
  }

  return (
    <div className="new-centre">
      {!formComplete && (
        <FormProgressIndicator
          activeFormStep={activeFormStep}
          title={"Set up a new centre"}
          totalFormSteps={totalFormSteps}
        />
      )}
      <SingleCentreForm
        activeFormStep={activeFormStep}
        centreNameMinLength={centreNameMinLength}
        centreNames={centreNames}
        centreReport={centreReport}
        centreReportTypeOptions={centreReportTypeOptions}
        centreReports={centreReports}
        clickBack={clickBack}
        formComplete={formComplete}
        formError={formError}
        history={props.history}
        isFormPristine={isFormPristine}
        setActiveFormStep={setActiveFormStep}
        setCentreReport={setCentreReport}
        setFormComplete={setFormComplete}
        setIsFormPristine={setIsFormPristine}
        totalFormSteps={totalFormSteps}
      />
    </div>
  );
};

export default withRouter(NewCentre);
