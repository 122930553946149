// Core
import React, { ReactElement } from "react";

// Components
import { mainTheme, Button } from "@cambridgeassessment/cambridge-ui";

// Interfaces
import { Respondent } from "@cpsq/ui-interfaces";
import { SelectedRespondentRow } from "../../interfaces/selected-respondent-row";

// Stylesheets
import "./respondentsTable.scss";

// Utils
import { getViewportSize } from "@cpsq/common-utils";
import {
  getHumanDateString,
  getHumanDateTimeString
} from "../../utils/date-helpers";
import { createMuiDatatableTheme } from "@cpsq/ui-components";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MuiThemeProvider, Theme } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";
import { History } from "history";
// @ts-ignore
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
  MUISortOptions
} from "mui-datatables";

interface Props {
  isCentreAdmin: boolean;
  activeStatus: string;
  checkRespondent: (selectedRespondentRows: SelectedRespondentRow[]) => void;
  clickSendInvite: (respondentIds: string[]) => void;
  clickRetryInvite: (respondentIds: string[]) => void;
  clickSendReminder: (respondentIds: string[]) => void;
  clickViewResult: (respondentId: string) => void;
  groupId: string | undefined;
  groupIsArchived: boolean;
  history: History;
  isLoadingGroupData: boolean;
  respondentIdsPendingInvite: string[];
  respondents: Respondent[];
  selectedRespondentRowIndexes: number[];
}

interface State {
  sortedColumn: MUISortOptions;
  viewportSize: string;
}

interface MUIDataTableOverrides extends Overrides {
  MUIDataTableFilterList: {
    chip: {
      margin: string;
    };
  };
}
interface MUIDataTableTheme extends Theme {
  overrides: MUIDataTableOverrides;
}

const getMuiTheme = (viewportSize: string): MUIDataTableTheme => {
  const theme = createMuiDatatableTheme(viewportSize);

  return {
    ...theme,
    typography: { ...mainTheme.typography },
    overrides: {
      ...theme.overrides,
      MuiButton: {
        sizeLarge: { minWidth: "120px" }
      },
      MUIDataTableFilterList: {
        chip: {
          margin: "8px"
        }
      }
    },
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: "rgba(0,0,0,.37)",
        dark: "rgba(0,0,0,.37)"
      }
    }
  } as MUIDataTableTheme;
};

class RespondentsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sortedColumn: { direction: "asc", name: "email" },
      viewportSize: getViewportSize(window.innerWidth)
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  render(): ReactElement {
    const options: MUIDataTableOptions = {
      selectToolbarPlacement: "none",
      download: false,
      filter: true,
      filterType: "dropdown",
      isRowSelectable: this.isRowSelectable.bind(this),
      onColumnSortChange: this.sortColumn.bind(this),
      onRowSelectionChange: this.selectRow.bind(this),
      pagination: false,
      print: false,
      responsive: "simple",
      rowsSelected: this.props.selectedRespondentRowIndexes,
      search: true,
      selectableRowsOnClick: true,
      viewColumns: false,
      sortOrder: this.state.sortedColumn,
      selectableRows: this.props.isCentreAdmin ? "multiple" : "none"
    };

    return (
      <MuiThemeProvider theme={getMuiTheme(this.state.viewportSize)}>
        <MUIDataTable
          data={this.props.respondents}
          columns={this.getColumns(this.props.activeStatus)}
          options={options}
          title=""
        />
      </MuiThemeProvider>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(activeStatus: string): any[] {
    const sharedColumns = {
      emails: {
        name: "email",
        label: "Email address",
        options: {
          customBodyRender: this.renderEmailColumnBody,
          sortOrder:
            this.state.sortedColumn.name === "email"
              ? this.state.sortedColumn.direction
              : "none",
          setCellProps: (): Record<string, Record<string, string>> => ({
            style: {
              whiteSpace: "normal",
              wordBreak: "break-word"
            }
          })
        }
      }
    };
    const sortByColumn = (columnName: string): string =>
      this.state.sortedColumn.name === columnName
        ? this.state.sortedColumn.direction
        : "none";
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const setCellProps = () => ({
      className: this.state.viewportSize === "mobile" ? "" : "align-right"
    });

    switch (activeStatus) {
      case "completed":
        return [
          sharedColumns.emails,
          {
            name: "completionDate",
            label: "Completion Date",
            options: {
              customBodyRender: this.convertToLocalDate(getHumanDateString),
              sortOrder: sortByColumn("completionDate")
            }
          },
          {
            name: "name",
            label: "Respondent Name",
            options: {
              customBodyRender: (name?: string): string =>
                name || "Removed Name",
              sortOrder: sortByColumn("name"),
              setCellProps: (): Record<string, Record<string, string>> => ({
                style: {
                  whiteSpace: "normal",
                  wordBreak: "break-word"
                }
              })
            }
          },
          ...(this.props.isCentreAdmin
            ? [
                {
                  name: "id",
                  label: " ",
                  options: {
                    empty: true,
                    filter: false,
                    setCellProps,
                    customBodyRender: this.renderCompletedColumnBody,
                    sort: false
                  }
                }
              ]
            : [])
        ];
      case "notSent":
        return [
          sharedColumns.emails,
          ...(this.props.isCentreAdmin
            ? [
                {
                  name: "invite",
                  label: " ",
                  options: {
                    empty: true,
                    filter: false,
                    setCellProps,
                    sort: false,
                    customBodyRender: this.renderSendInviteColumnBody.bind(this)
                  }
                }
              ]
            : [])
        ];
      case "sent":
        return [
          sharedColumns.emails,
          {
            name: "dateInvited",
            label: "Date invited",
            options: {
              customBodyRender: this.convertToLocalDate(getHumanDateString),
              sortOrder: sortByColumn("dateInvited")
            }
          },
          {
            name: "startDate",
            label: "Date started",
            options: {
              customBodyRender: this.convertToLocalDate(getHumanDateString),
              sortOrder: sortByColumn("startDate")
            }
          },
          {
            name: "dateLastReminded",
            label: "Date last reminded",
            options: {
              customBodyRender: this.convertToLocalDate(getHumanDateTimeString),
              sortOrder: sortByColumn("dateLastReminded")
            }
          },
          ...(this.props.isCentreAdmin
            ? [
                {
                  name: "remind",
                  label: " ",
                  options: {
                    empty: true,
                    filter: false,
                    setCellProps,
                    sort: false,
                    customBodyRender: this.renderSendReminderColumnBody.bind(
                      this
                    )
                  }
                }
              ]
            : [])
        ];
      default:
        return [];
    }
  }

  convertToLocalDate = (formatFunction: Function): Function => {
    return (dateString: string): string =>
      dateString ? formatFunction(new Date(dateString)) : "";
  };

  getRespondent(email: string): Respondent | undefined {
    return this.props.respondents.find(r => r.email === email);
  }

  getRespondentId(email: string): string {
    const respondent = this.getRespondent(email);
    return respondent ? respondent.id : "";
  }

  getSelectedRespondentRows(indexes: number[]): SelectedRespondentRow[] {
    return indexes.map(index => {
      return {
        respondentId: this.props.respondents[index].id,
        rowIndex: index
      };
    });
  }

  handleWindowResize(): void {
    const viewportSize = getViewportSize(window.innerWidth);

    if (this.state.viewportSize === viewportSize) {
      return;
    }

    this.setState({
      viewportSize
    });
  }

  isRowSelectable(dataIndex: number): boolean {
    const respondent = this.props.respondents[dataIndex];

    if (!respondent) {
      return true;
    }

    return !this.props.respondentIdsPendingInvite.includes(respondent.id);
  }

  renderCompletedColumnBody = (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: any
    /* eslint-enable @typescript-eslint/no-explicit-any */
  ): ReactElement => {
    return (
      <Button
        variant="text"
        color="primary"
        onClick={(): void => {
          this.props.clickViewResult(
            this.getRespondentId(tableMeta.rowData[0])
          );
        }}
        className="button"
        data-testid="View results"
      >
        View results
      </Button>
    );
  };

  renderEmailColumnBody = (
    /* eslint-disable @typescript-eslint/no-explicit-any */
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: any
    /* eslint-enable @typescript-eslint/no-explicit-any */
  ): ReactElement => {
    return <span className="bold">{value ? value : "Removed email"}</span>;
  };

  renderSendInviteColumnBody(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: any
    /* eslint-enable @typescript-eslint/no-explicit-any */
  ): ReactElement {
    const respondent = this.getRespondent(tableMeta.rowData[0]);
    const respondentId = respondent ? respondent.id : "";

    if (
      respondent &&
      this.props.respondentIdsPendingInvite.includes(respondentId) &&
      respondent.dateInvited
    ) {
      const invited = respondent.dateInvited;

      // we say the pending state has timeout out if the request was sent more than 5 min (300,000 ms) ago
      const pendingTimedOut = Date.now() - new Date(invited) > 300000;
      if (pendingTimedOut) {
        return (
          <Button
            variant="text"
            color="primary"
            startIcon={<FontAwesomeIcon icon="envelope" />}
            disabled={this.props.groupIsArchived || !respondent?.email}
            onClick={(): void => {
              this.props.clickRetryInvite([respondentId]);
            }}
            className="button"
            data-testid="Retry"
          >
            Retry
          </Button>
        );
      } else {
        return (
          <div className="invite-sending">
            <FontAwesomeIcon icon="paper-plane" /> Invite sending...
          </div>
        );
      }
    } else {
      return (
        <Button
          variant="text"
          color="primary"
          disabled={this.props.groupIsArchived || !respondent?.email}
          startIcon={<FontAwesomeIcon icon="envelope" />}
          onClick={(): void => {
            this.props.clickSendInvite([respondentId]);
          }}
          className="button"
          data-testid="Send invite"
        >
          Send invite
        </Button>
      );
    }
  }

  renderSendReminderColumnBody(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: any
    /* eslint-enable @typescript-eslint/no-explicit-any */
  ): ReactElement {
    const respondent = this.getRespondent(tableMeta.rowData[0]);
    const respondentId = respondent ? respondent.id : "";

    return (
      <Button
        variant="text"
        color="primary"
        startIcon={<FontAwesomeIcon icon="envelope" />}
        disabled={this.props.isLoadingGroupData || !respondent?.email}
        onClick={(): void => {
          this.props.clickSendReminder([respondentId]);
        }}
        className="button"
        data-testid="Send reminder"
      >
        Send reminder
      </Button>
    );
  }

  selectRow(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    currentRowsSelected: any[],
    allRowsSelected: any[]
    /* eslint-enable @typescript-eslint/no-explicit-any */
  ): void {
    const selectedIndexes = allRowsSelected.map(row => row.dataIndex);

    this.props.checkRespondent(this.getSelectedRespondentRows(selectedIndexes));
  }

  sortColumn = (
    name: MUISortOptions["name"],
    direction: MUISortOptions["direction"]
  ): void => {
    this.setState({
      sortedColumn: {
        direction,
        name
      }
    });
  };
}

export default RespondentsTable;
