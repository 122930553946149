// Core
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";

// Components
import EditCentreForm from "../editCentreForm/editCentreForm";
import "toastr/build/toastr.min.css";
import { Typography } from "@material-ui/core";

// Interfaces
import { Centre } from "@cpsq/ui-interfaces";
import { CentreReport } from "../../interfaces/centre-report";

// Utils
import { useAuth } from "@cpsq/auth-frontend";
import Api from "../../utils/api";
import {
  handleGetCentreReports,
  runToastr
} from "../../utils/centre-form-helpers";

interface SaveResponse {
  entity: Centre;
  message: string;
}

interface Props {
  centre: Centre;
}

const ManageCentre: FC<Props> = (props: Props): ReactElement => {
  const [centre, setCentre] = useState({
    name: props.centre.name,
    centreId: props.centre.centreId
  });
  const [centreNameError, setCentreNameError] = useState("");
  const [centreNameMinLengthError, setCentreNameMinLengthError] = useState("");
  const [centreNames, setCentreNames] = useState([] as string[]);
  const [centreReport, setCentreReport] = useState({
    name: "",
    normGroup: props.centre.defaultNormGroup,
    testType: props.centre.defaultTestType,
    type: props.centre.defaultReportType
  } as CentreReport);
  const [centreReportTypeOptions, setCentreReportTypeOptions] = useState({});
  const [centreReports, setCentreReports] = useState([] as CentreReport[]);
  const [formError] = useState("");
  const { isInternalAdmin } = useAuth();
  const [isFormPristine, setIsFormPristine] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const centreNameMinLength = 2;

  useEffect(() => {
    const handleGetCentres = (response: Centre[]): void => {
      setCentreNames(
        response
          .filter(item => item.name !== props.centre.name)
          .map(item => item.name.toLowerCase())
      );
    };

    if (!centreNames.length) {
      Api.getCentres(handleGetCentres);
    }
  }, [centreNames, props.centre.name]);

  useEffect(() => {
    Api.getCentreReports((response: CentreReport[]) => {
      handleGetCentreReports(
        response,
        setCentreReports,
        setCentreReportTypeOptions
      );
    });
  }, []);

  const handleUpdateCentre = useCallback((response: SaveResponse): void => {
    if (runToastr(response.message)) setIsSubmitted(true);
  }, []);

  const clickSubmit = (): void => {
    Api.updateCentre(
      props.centre.centreId,
      Object.assign(props.centre, {
        defaultNormGroup: centreReport.normGroup,
        defaultReportType: centreReport.type,
        defaultTestType: centreReport.testType,
        name: centre.name
      }),
      handleUpdateCentre
    );
  };

  return (
    <div className="manage-centre">
      <section className="centre-details">
        <Typography variant="h4" className="gamma bold">
          Centre details
        </Typography>
        {isInternalAdmin && (
          <EditCentreForm
            centreName={centre.name}
            centreNameError={centreNameError}
            centreNameMinLength={centreNameMinLength}
            centreNameMinLengthError={centreNameMinLengthError}
            centreNames={centreNames}
            centreReport={centreReport}
            centreReportTypeOptions={centreReportTypeOptions}
            centreReports={centreReports}
            formError={formError}
            formAction="edit"
            clickSubmit={clickSubmit}
            isFormPristine={isFormPristine}
            setCentre={setCentre}
            setCentreNameError={setCentreNameError}
            setCentreNameMinLengthError={setCentreNameMinLengthError}
            setCentreReport={setCentreReport}
            setIsFormPristine={setIsFormPristine}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
          />
        )}
        {!isInternalAdmin && (
          <dl>
            <div>
              <Typography variant="h1" className="bold">
                Centre name
              </Typography>
              <dd data-testid="centre-name">{props.centre.name}</dd>
            </div>
          </dl>
        )}
      </section>
    </div>
  );
};

export default ManageCentre;
