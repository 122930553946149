// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Interfaces
import { ReportMonth } from "../../interfaces/report-month";

// Stylesheets
import "./testUsageChart.scss";

// Utils
import { getViewportSize } from "@cpsq/common-utils";

// Vendor
import { Line } from "react-chartjs-2";
import * as chartjs from "chart.js";
import dayjs from "dayjs";

interface Props {
  report: ReportMonth[];
}

const TestUsageChart: FC<Props> = (props: Props): ReactElement => {
  const invitesSentData: number[] = [];
  const testsCompleteData: number[] = [];
  const [viewportSize, setViewportSize] = useState(
    getViewportSize(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = (): void => {
      const viewportSizeFromUtil = getViewportSize(window.innerWidth);

      if (viewportSize === viewportSizeFromUtil) {
        return;
      }

      setViewportSize(viewportSizeFromUtil);
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, [viewportSize]);

  props.report.forEach((reportMonth: ReportMonth) => {
    invitesSentData.push(reportMonth.invitesSent);
    testsCompleteData.push(reportMonth.testsCompleted);
  });

  const yearsFromReport = Array.from(
    new Set(props.report.map(item => dayjs(item.date).format("YYYY")))
  );
  const isMultipleYears = yearsFromReport.length > 1;

  const data: chartjs.ChartData = {
    labels: isMultipleYears
      ? yearsFromReport
      : [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
    datasets: [
      {
        backgroundColor: "#000000",
        borderColor: "#000000",
        data: invitesSentData,
        fill: false,
        label: "Invites sent"
      },
      {
        backgroundColor: "#6cb42c",
        borderColor: "#6cb42c",
        data: testsCompleteData,
        fill: false,
        label: "Tests completed"
      }
    ]
  };

  const options: chartjs.ChartOptions = {
    animation: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      duration: (window as any).Cypress ? 0 : 1000
    },
    hover: {
      animationDuration: 0
    },
    elements: {
      line: {
        fill: false
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onResize: function(chart: any) {
      if (viewportSize === "mobile") {
        chart.options.maintainAspectRatio = false;
      } else {
        chart.options.maintainAspectRatio = true;
      }

      chart.update();

      chart.options.legend.position =
        viewportSize === "desktop" ? "right" : "bottom";
      chart.update();
    },
    maintainAspectRatio: viewportSize !== "mobile",
    responsive: true,
    scales: {
      xAxes: [
        {
          display: true,
          offset: true,
          ticks: {
            fontColor: "#000000",
            fontFamily: "Source Sans Pro",
            fontStyle: "600",
            fontSize: 20
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#000000",
            fontFamily: "Source Sans Pro",
            fontSize: 20,
            fontStyle: "600"
          }
        }
      ]
    },
    tooltips: {
      mode: "label"
    }
  };

  const legendOptions: chartjs.ChartLegendOptions = {
    position: viewportSize === "desktop" ? "right" : "bottom",
    labels: {
      fontFamily: "Source Sans Pro",
      fontColor: "#000000",
      fontSize: 16,
      fontStyle: "600",
      padding: 20
    }
  };

  return (
    <div className="chart-container">
      <Line data={data} options={options} legend={legendOptions} />
    </div>
  );
};

export default TestUsageChart;
