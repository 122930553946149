import { validateEmail } from "@cpsq/common-utils";

export const extractEmailsFromText = (
  emailList: string
): { validEmails: string[]; invalidEmails: string[] } => {
  const validEmails: string[] = [];
  const invalidEmails: string[] = [];

  const emails = emailList.split(/[\n\s,]+/);

  emails.forEach((email: string) => {
    if (validateEmail(email)) {
      validEmails.push(email);
    } else {
      if (email) {
        invalidEmails.push(email);
      }
    }
  });

  return { validEmails: validEmails, invalidEmails: invalidEmails };
};
