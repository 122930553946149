// Core
import React, {
  FC,
  ReactElement,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer
} from "react";

// Components
import { GenericForm, RadioGroup } from "@cpsq/ui-components";
import { Button } from "@cambridgeassessment/cambridge-ui";
import Select, { ActionMeta, OptionProps, ValueType } from "react-select";

// Stylesheets
import "./editCentreForm.scss";

// Interfaces
import { CentreReport } from "../../interfaces/centre-report";

// Utils
import {
  changeName,
  changeReportType,
  clearSelectedCentre
} from "../../utils/centre-form-helpers";
import { Centre } from "@cpsq/ui-interfaces";
import Api from "../../utils/api";

//Vendor
import { debounce } from "debounce";

interface Props {
  activeFormStep?: number;
  centreName: string;
  centreNameError: string;
  centreNameMinLength: number;
  centreNameMinLengthError: string;
  centreNames: string[];
  centreReport: CentreReport;
  centreReportTypeOptions: {};
  centreReports: CentreReport[];
  clickBack?: () => void;
  clickNext?: () => void;
  clickSubmit?: () => void;
  createCentreError?: string;
  formAction: "create" | "edit";
  formError: string;
  isFormPristine: boolean;
  setCentre: Dispatch<SetStateAction<Centre>>;
  setCentreNameError: Dispatch<SetStateAction<string>>;
  setCentreNameMinLengthError: Dispatch<SetStateAction<string>>;
  setCentreReport: Dispatch<SetStateAction<CentreReport>>;
  setIsFormPristine: Dispatch<SetStateAction<boolean>>;
  isSubmitted?: boolean;
  setIsSubmitted?: Dispatch<SetStateAction<boolean>>;
}

type State = {
  loading: boolean;
  inputValue: string;
  centres: Centre[];
};

const initialState: State = {
  loading: false,
  inputValue: "",
  centres: []
};

type Actions =
  | ["SET_LOADING", boolean]
  | ["SET_INPUT", string]
  | ["SET_CENTRES", Centre[], string?];

const reducer = (state: State, action: Actions): State => {
  switch (action[0]) {
    case "SET_LOADING": {
      return {
        ...state,
        loading: action[1]
      };
    }

    case "SET_INPUT": {
      return {
        ...state,
        inputValue: action[1]
      };
    }

    case "SET_CENTRES": {
      const [, centres, input] = action;
      if (input === undefined || state.inputValue === input) {
        return {
          ...state,
          centres
        };
      }
      return state;
    }

    default:
      return state;
  }
};

const EditCentreForm: FC<Props> = (props: Props): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, inputValue, centres } = state;

  const debounceFindCentre = useCallback(debounce(Api.findCentre, 200), []);

  const search = useCallback(
    (text: string) =>
      debounceFindCentre(text, (foundCentres: Centre[]) => {
        dispatch(["SET_CENTRES", foundCentres, text]);
        dispatch(["SET_LOADING", false]);
      }),
    [debounceFindCentre]
  );

  useEffect(() => {
    if (inputValue) {
      dispatch(["SET_LOADING", true]);
      search(inputValue);
    } else {
      dispatch(["SET_LOADING", false]);
      dispatch(["SET_CENTRES", []]);
    }
  }, [inputValue, debounceFindCentre, search]);

  const onOptionSelect = (
    centre: ValueType<Centre>,
    { action }: ActionMeta<Centre>
  ): void => {
    if (action === "clear") {
      dispatch(["SET_CENTRES", []]);

      return clearSelectedCentre(
        props.setCentre,
        props.setCentreNameError,
        props.setCentreNameMinLengthError
      );
    }

    return changeName(
      centre as Centre,
      props.centreNameError,
      props.centreNameMinLength,
      props.centreNames,
      props.isFormPristine,
      props.setCentre,
      props.setCentreNameError,
      props.setCentreNameMinLengthError,
      props.setIsFormPristine
    );
  };

  const handleInputChange = useCallback(
    (value: string) => {
      debounceFindCentre.clear();
      dispatch(["SET_INPUT", value]);
    },
    [debounceFindCentre]
  );

  const renderButtons = (): ReactElement[] => {
    let buttons;
    if (props.formAction === "create") {
      buttons = [
        <Button
          color="default"
          variant="text"
          key="back"
          onClick={props.clickBack}
          className="button"
          data-testid="Back"
        >
          Back
        </Button>,
        <Button
          color="primary"
          key="submit"
          disabled={
            props.activeFormStep === 1
              ? !props.centreName ||
                props.centreNameError !== "" ||
                props.centreNameMinLengthError !== "" ||
                !props.centreNames.length
              : !Object.keys(props.centreReport).length
          }
          onClick={props.clickNext}
          data-testid="Next"
        >
          Next
        </Button>
      ];
    } else {
      buttons = [
        <Button
          color="primary"
          key="submit"
          disabled={
            !props.centreName ||
            props.centreNameError !== "" ||
            props.centreNameMinLengthError !== "" ||
            !props.centreNames.length ||
            props.isFormPristine ||
            props.isSubmitted
          }
          onClick={props.clickSubmit}
          data-testid="Submit"
        >
          Submit
        </Button>
      ];
    }

    return buttons;
  };

  const renderOption = (optionProps: OptionProps<Centre>): ReactElement => {
    const { innerProps, innerRef } = optionProps;

    return (
      <div ref={innerRef} {...innerProps} className="custom-option">
        <span className="bold">
          {optionProps.data.name} [{optionProps.data.centreId}]
        </span>
      </div>
    );
  };

  const renderControls = (): {
    details?: ReactElement | null;
    error?: string;
    input: ReactElement;
    label: string;
    optional?: boolean;
  }[] => {
    const centreSearchControl = {
      label: "Centre name",
      error: props.centreNameError || props.centreNameMinLengthError,
      input: (
        <div className="react-select-container">
          <div className="react-select-input">
            <Select
              className="react-select"
              classNamePrefix="react-select"
              components={{
                DropdownIndicator: (): null => null,
                IndicatorSeparator: (): null => null,
                Option: renderOption
              }}
              getOptionLabel={(option): string => option.name}
              getOptionValue={(option): string => option.centreId}
              onChange={onOptionSelect}
              onInputChange={handleInputChange}
              placeholder="Search for centre"
              inputValue={inputValue}
              options={(!loading && centres) || []}
              isLoading={loading}
              isClearable
            />
          </div>
        </div>
      )
    };

    const reportTypeControl = {
      label: "Report type",
      error:
        props.formAction === "create" ? props.createCentreError : undefined,
      input: (
        <RadioGroup
          id="report-type"
          value={props.centreReport.type}
          options={props.centreReportTypeOptions}
          handleChange={(value: string): void =>
            changeReportType(
              value,
              props.centreReport,
              props.centreReports,
              props.isFormPristine,
              props.setCentreReport,
              props.setIsFormPristine,
              props.setIsSubmitted
            )
          }
        />
      )
    };

    if (props.formAction === "create") {
      return props.activeFormStep === 1
        ? [centreSearchControl]
        : [reportTypeControl];
    } else {
      return [reportTypeControl];
    }
  };

  return (
    <GenericForm
      name={`${props.formAction}-centre`}
      formError={props.formError}
      buttons={renderButtons()}
      formControls={renderControls()}
    />
  );
};

export default EditCentreForm;
