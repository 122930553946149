// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import CentresTable from "../../components/centresTable/centresTable";
import { Typography } from "@material-ui/core";
import { Button } from "@cambridgeassessment/cambridge-ui";

// Interfaces
import { Centre } from "@cpsq/ui-interfaces";
import { CentreReport } from "../../interfaces/centre-report";

// Stylesheets
import "./centres.scss";
import "./centres-responsive.scss";

// Utils
import { rerouteWithState } from "@cpsq/common-utils";
import Api from "../../utils/api";

// Vendor
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CentreWithPendingStatus extends Centre {
  pending?: boolean;
}

interface Props extends RouteComponentProps {
  history: History;
}

const Centres: FC<Props> = (props: Props): ReactElement => {
  const [centres, setCentres] = useState({
    timestamp: "",
    data: [] as CentreWithPendingStatus[]
  });
  const isMounted = React.useRef(false);
  const [centreReports, setCentreReports] = useState([] as CentreReport[]);

  useEffect(() => {
    isMounted.current = true;

    return (): void => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    Api.getCentreReports((response: CentreReport[]) => {
      setCentreReports(response);
    });
  }, []);

  useEffect(() => {
    const handleGetCentres = (response: CentreWithPendingStatus[]): void => {
      if (isMounted.current) {
        setCentres({ timestamp: Date.now().toString(), data: response });
      }
    };

    if (isMounted.current) {
      Api.getCentres(handleGetCentres);
    }
  }, []);

  const clickNewCentre = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rerouteWithState(props.history as any, "/new-centre/", {
      centreNames: centres.data.map(centre => centre.name.toLowerCase())
    });
  };

  return (
    <div className="centres">
      <header className="breakout">
        <div className="column-one">
          <Typography variant="h3" className="section-heading beta bold">
            Centres
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className="section-sub-heading delta bold"
          >
            Manage centres that use Cambridge Assessment products
          </Typography>
        </div>
        <div className="column-two">
          <Button
            color="default"
            startIcon={<FontAwesomeIcon icon="plus" />}
            onClick={clickNewCentre}
            data-testid="New centre(s)"
          >
            New centre(s)
          </Button>
        </div>
      </header>
      <CentresTable
        centres={centres.data}
        centreReports={centreReports}
        history={props.history}
      />
    </div>
  );
};

export default withRouter(Centres);
