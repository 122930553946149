// Core
import React, { ReactElement } from "react";

// Components
import { GenericForm } from "@cpsq/ui-components";
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Typography } from "@material-ui/core";

// Interfaces
import { AddRespondentsHttpResponse } from "../../interfaces/http-responses/add-respondents-http-response";
import { ErrorHttpResponse } from "../../interfaces/http-responses/error-http-response";
import { Group, Respondent } from "@cpsq/ui-interfaces";

// Stylesheets
import "./addRespondents.scss";

// Utils
import { Session } from "@cpsq/auth-frontend";
import { isErrorHttpResponse, rerouteWithState } from "@cpsq/common-utils";
import Api from "../../utils/api";
import { extractEmailsFromText } from "./respondentProcessing";
import { getRespondentIdsByStatus } from "../../utils/group-helpers";

// Vendor
import { History } from "history";
import queryString from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  emailPasteBlock: string;
  error: string;
  feedback: string;
  groupId: string;
  notSentRespondentIds: string[];
  rejectSubmit: boolean;
}

export class AddRespondents extends React.Component<Props, State> {
  centreId = "";
  private nextClicked = false;

  constructor(props: Props) {
    super(props);

    const values = queryString.parse(this.props.history.location.search);
    let groupId = values.groupId as string;

    if (!groupId) {
      groupId = "";
      this.props.history.push("/centre-dashboard/");
    }
    const notSentRespondentIds = this.getNotSentRespondentIds(
      this.props.history
    );

    this.state = {
      emailPasteBlock: "",
      error: "",
      feedback: "",
      groupId: groupId,
      notSentRespondentIds,
      rejectSubmit: false
    };

    this.handleGetGroup = this.handleGetGroup.bind(this);
  }

  componentDidMount(): void {
    this.centreId = Session.getSessionValue("centreId");

    if (!this.state.notSentRespondentIds.length) {
      if (this.state.groupId) {
        Api.getGroupDashboard(
          this.centreId,
          this.state.groupId,
          this.handleGetGroup
        );
      } else {
        this.props.history.push("/centre-dashboard/");
      }
    }
  }

  render(): ReactElement {
    return (
      <section className="add-respondents">
        <Typography
          variant="h3"
          gutterBottom
          className="section-heading-form beta bold"
        >
          {this.state.error ? "Check respondent emails" : "Add respondents"}
        </Typography>
        <GenericForm
          name="add-respondents"
          buttons={[
            <Button
              variant="text"
              color="default"
              key="cancel"
              onClick={this.clickCancel}
              className="button"
              data-testid="Cancel"
            >
              Cancel
            </Button>,
            <Button
              color="default"
              key="save"
              onClick={this.clickSave}
              type="submit"
              className="button"
              data-testid="Save and exit"
            >
              Save and exit
            </Button>,
            <Button
              color="primary"
              key="next"
              onClick={this.clickNext}
              type="submit"
              className="button"
              data-testid="Next"
            >
              Next
            </Button>
          ]}
          formControls={[
            {
              label: "Enter emails manually",
              details: this.state.feedback ? (
                <div className="feedback-message">{this.state.feedback}</div>
              ) : null,
              error: this.state.error,
              input: (
                <textarea
                  rows={5}
                  placeholder={
                    "Paste, one email on each line or separated by commas or spaces."
                  }
                  id="emailPasteBlock"
                  value={this.state.emailPasteBlock}
                  onChange={this.handleInputChange}
                />
              )
            }
          ]}
        />
      </section>
    );
  }

  clickCancel = (): void => {
    this.props.history.push("/centre-dashboard/");
  };

  clickNext = (): void => {
    this.nextClicked = true;

    this.clickSave();
  };

  checkRespondentsBeforeProceeding = (respondents: Respondent[]): void => {
    if (!respondents.length) {
      this.setState({ error: "Cannot proceed without adding respondents" });
    } else if (!this.state.notSentRespondentIds.length) {
      this.setState({
        error: "Cannot proceed. All of your respondents have been sent emails"
      });
    } else {
      rerouteWithState(
        this.props.history,
        `/create-invites?groupId=${this.state.groupId}`,
        {
          respondentIds: this.state.notSentRespondentIds,
          fromURL: "add-respondents"
        }
      );
    }
  };

  clickSave = (): void => {
    const { invalidEmails, validEmails } = extractEmailsFromText(
      this.state.emailPasteBlock
    );

    if (!invalidEmails.length && !validEmails.length) {
      if (this.nextClicked) {
        Api.getRespondents(
          this.centreId,
          this.state.groupId,
          this.checkRespondentsBeforeProceeding,
          () => this.checkRespondentsBeforeProceeding([])
        );

        this.nextClicked = false;

        return;
      } else {
        this.props.history.push("/centre-dashboard/");

        return;
      }
    }

    if (invalidEmails.length > 0) {
      this.setState({
        rejectSubmit: true,
        emailPasteBlock: invalidEmails.join("\n")
      });
    } else {
      this.setState({ rejectSubmit: false });
    }

    if (validEmails.length > 0) {
      Api.addRespondents(
        this.centreId,
        this.state.groupId,
        validEmails,
        this.handleAddedRespondents
      );
    } else {
      this.nextClicked = false;
    }

    this.setState({
      error: this.setErrorState(invalidEmails, validEmails)
    });
  };

  getNotSentRespondentIds = (history: History): string[] => {
    if (!history) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyLocationState: any = history.location.state;

    if (!historyLocationState || !historyLocationState.notSentRespondentIds) {
      return [];
    }

    return historyLocationState.notSentRespondentIds;
  };

  getProblemEmails(
    emailPasteBlock: string,
    entity: AddRespondentsHttpResponse["entity"]
  ): string {
    const { invalidEmails } = extractEmailsFromText(emailPasteBlock);

    return entity.failedEntities
      .map(failedEntity => failedEntity.email)
      .concat(invalidEmails)
      .toString();
  }

  getResponseText = (entity: AddRespondentsHttpResponse["entity"]): string => {
    let message = "";

    if (entity.successes) {
      message += `${entity.successes} ${
        entity.successes !== 1 ? "emails have" : "email has"
      } been added to this group. `;
    }

    if (entity.duplicates) {
      message += "Duplicates removed. ";
    }

    if (entity.conflicts) {
      message += `${entity.conflicts} ${
        entity.conflicts !== 1 ? "emails are" : "email is"
      } already in this group: ${entity.conflictedEntities
        .map(conflict => conflict.email)
        .join(", ")}. `;
    }

    if (entity.failures) {
      message += `${entity.failures} ${
        entity.failures !== 1 ? "emails have" : "email has"
      } failed to be added.`;
    }

    return message;
  };

  handleAddedRespondents = (
    response: AddRespondentsHttpResponse | ErrorHttpResponse
  ): void => {
    if (!isErrorHttpResponse(response)) {
      this.setState({
        emailPasteBlock: this.getProblemEmails(
          this.state.emailPasteBlock,
          response.entity
        ),
        feedback: this.getResponseText(response.entity)
      });

      if (
        response.entity &&
        response.entity.createdEntities &&
        this.nextClicked
      ) {
        const createdRespondentIds = response.entity.createdEntities.map(
          respondent => respondent.id
        );

        this.setState({
          notSentRespondentIds: [
            ...this.state.notSentRespondentIds,
            ...createdRespondentIds
          ]
        });
      }
    }

    if (!isErrorHttpResponse(response) && !this.state.rejectSubmit) {
      if (this.nextClicked) {
        rerouteWithState(
          this.props.history,
          `/create-invites?groupId=${this.state.groupId}`,
          {
            respondentIds: this.state.notSentRespondentIds,
            fromURL: "add-respondents"
          }
        );
      } else {
        this.props.history.push("/centre-dashboard/");
      }
    } else {
      if (!this.state.rejectSubmit) {
        const responseObject: AddRespondentsHttpResponse["entity"] = JSON.parse(
          response.message
        );
        const error = this.getResponseText(JSON.parse(response.message));

        this.nextClicked = false;

        this.setState({
          error: error,
          emailPasteBlock: this.getProblemEmails(
            this.state.emailPasteBlock,
            responseObject
          )
        });
      }
    }
  };

  handleGetGroup(group: Group): void {
    const notSentRespondentIds = getRespondentIdsByStatus(group, "notSent");

    this.setState({
      notSentRespondentIds
    });
  }

  handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const emailPasteBlock = event.currentTarget.value;

    this.setState({ emailPasteBlock: emailPasteBlock });
  };

  setErrorState = (invalidEmails: string[], validEmails: string[]): string => {
    if (invalidEmails.length && validEmails.length) {
      return "These emails were not added, they are not formatted correctly. Edit or delete:";
    }

    if (!invalidEmails.length && validEmails.length) {
      return "";
    }

    return "No emails added as they were all in an invalid format";
  };
}

export default withRouter(AddRespondents);
