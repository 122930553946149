// Core
import React, { ReactElement } from "react";

// Components
import { AuthRedirect, PrivateRoute } from "@cpsq/auth-frontend";
import { AuthenticatedLayout, SelectOrganisation } from "@cpsq/ui-components";

// Pages
import TestUsage from "./pages/test-usage/testUsage";
import AddRespondents from "./pages/addRespondents/addRespondents";
import CentreDashboard from "./pages/centreDashboard/centreDashboard";
import Confirmation from "./pages/confirmation/confirmation";
import CreateInvites from "./pages/createInvites/createInvites";
import GroupResults from "./pages/groupResults/groupResults";
import GroupDashboard from "./pages/groupDashboard/groupDashboard";
import NewGroup from "./pages/newGroup/newGroup";
import CentreSettings from "./pages/centreSettings/centreSettings";
import ViewResults from "./pages/viewResults/viewResults";
import NewCentre from "./pages/newCentre/newCentre";
import GroupConfirmation from "./pages/groupConfirmation/groupConfirmation";
import Home from "./pages/home/home";
import Dashboard from "./pages/dashboard/dashboard";
import ApiKey from "./pages/apiKey/apiKey";
import ApiSpecification from "./pages/apiSpecification/apiSpecification";

// Stylesheets
import "./assets/stylesheets/application.scss";

// Utils
import { Session, useAuth, getCentreId } from "@cpsq/auth-frontend";
import Api from "./utils/api";

// Vendor
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faArchive,
  faBars,
  faChalkboardTeacher,
  faBoxOpen,
  faChartBar,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCog,
  faCoins,
  faCopy,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFilePdf,
  faHome,
  faKey,
  faLandmark,
  faMinus,
  faPaperPlane,
  faPen,
  faPlus,
  faRedo,
  faSchool,
  faSearch,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faStopwatch,
  faTasks,
  faTrash,
  faSync,
  faTimes,
  faTimesCircle,
  faUserCircle,
  faUsers,
  faSignInAlt,
  faTheaterMasks,
  faQuestionCircle,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { Redirect, Route } from "react-router-dom";
import Centres from "./pages/centres/centres";

library.add(
  faAngleLeft,
  faArchive,
  faBars,
  faBoxOpen,
  faChartBar,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCog,
  faCoins,
  faCopy,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFilePdf,
  faChalkboardTeacher,
  faHome,
  faKey,
  faLandmark,
  faMinus,
  faPaperPlane,
  faPen,
  faPlus,
  faRedo,
  faSchool,
  faSearch,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faStopwatch,
  faTasks,
  faTrash,
  faSync,
  faTimes,
  faTimesCircle,
  faUserCircle,
  faUsers,
  faSignInAlt,
  faTheaterMasks,
  faQuestionCircle,
  faInfoCircle
);

const App = (): ReactElement => {
  const {
    isAuthenticated,
    isInternalAdmin,
    isCPSQAdmin,
    signOut,
    getToken,
    isLoading,
    showSelectOrganisationView,
    user
  } = useAuth();

  const serviceName = "admin";
  Api.getAuthToken = getToken;

  if (isLoading) {
    return <></>;
  }

  if (user && showSelectOrganisationView) {
    return (
      <SelectOrganisation
        user={user}
        Session={Session}
        getCentreId={getCentreId}
      />
    );
  }

  return (
    <>
      <Route
        exact
        path="/"
        component={(): JSX.Element => {
          if (isAuthenticated) {
            return <Redirect to="/dashboard" />;
          } else {
            Session.clearSession();
            return <Redirect to="/welcome" />;
          }
        }}
      />
      <Route path="/welcome" exact component={Home} />
      <Route
        exact
        path="/callback/"
        render={(props): ReactElement => {
          return (
            <AuthRedirect
              serviceName={serviceName}
              isAuthenticated={isAuthenticated}
              {...props}
            />
          );
        }}
      />
      <Route
        render={(props): ReactElement => {
          if (!isLoading && !isAuthenticated) {
            Session.clearSession();
            return <Redirect to="/welcome" />;
          }

          if (isInternalAdmin) {
            return (
              <AuthenticatedLayout
                getCentre={Api.getCentre}
                getCentreFeatures={Api.getCentreFeatures}
                heading="Cambridge Personal Styles Questionnaire"
                isAuthenticated={isAuthenticated}
                isInternalAdmin={isInternalAdmin}
                isCPSQAdmin={isCPSQAdmin}
                serviceName={serviceName}
                Session={Session}
                signOut={signOut}
                token={getToken}
                user={user}
                {...props}
              >
                <Route path="/dashboard/" exact component={Dashboard} />
                <PrivateRoute path="/test-usage/" component={TestUsage} />
                <PrivateRoute
                  component={CentreSettings}
                  path="/centre-settings/"
                />
                <PrivateRoute component={Centres} path="/centres/" />
                <PrivateRoute
                  path="/group-dashboard/"
                  component={GroupDashboard}
                />
                <PrivateRoute path="/new-centre/" component={NewCentre} />
              </AuthenticatedLayout>
            );
          }

          return (
            <AuthenticatedLayout
              getCentre={Api.getCentre}
              getCentreFeatures={Api.getCentreFeatures}
              heading="Cambridge Personal Styles Questionnaire"
              isAuthenticated={isAuthenticated}
              isInternalAdmin={isInternalAdmin}
              isCPSQAdmin={isCPSQAdmin}
              serviceName={serviceName}
              Session={Session}
              signOut={signOut}
              token={getToken}
              user={user}
              {...props}
            >
              <Route path="/dashboard/" exact component={Dashboard} />
              <PrivateRoute
                path="/add-respondents/"
                component={AddRespondents}
              />
              <PrivateRoute
                path="/confirm-group/:id"
                component={GroupConfirmation}
              />
              <PrivateRoute
                path="/centre-dashboard/"
                component={CentreDashboard}
              />
              <PrivateRoute path="/confirmation/" component={Confirmation} />
              <PrivateRoute path="/create-invites/" component={CreateInvites} />
              <PrivateRoute path="/group-results/" component={GroupResults} />
              <PrivateRoute path="/test-usage/" component={TestUsage} />
              <PrivateRoute
                path="/group-dashboard/"
                component={GroupDashboard}
              />
              <PrivateRoute path="/new-group/" component={NewGroup} />
              <PrivateRoute path="/results/" component={ViewResults} />
              <PrivateRoute path="/api-key/" exact component={ApiKey} />
              <PrivateRoute
                path="/api-key/specification"
                component={ApiSpecification}
              />
            </AuthenticatedLayout>
          );
        }}
      />
    </>
  );
};

export default App;
