import { CentreReport } from "../interfaces/centre-report";
import { Dispatch, SetStateAction } from "react";
import toastr from "toastr";
import { Centre } from "@cpsq/ui-interfaces";

export const changeName = (
  centre: Centre,
  centreNameError: string,
  centreNameMinLength: number,
  centreNames: string[],
  isFormPristine: boolean,
  setCentre: (centre: { name: string; centreId: string }) => void,
  setCentreNameError: (name: string) => void,
  setCentreNameMinLengthError: (name: string) => void,
  setIsFormPristine: (isPristine: boolean) => void
): void => {
  if (isFormPristine) {
    setIsFormPristine(false);
  }

  const { name, centreId } = centre;

  if (name.length <= centreNameMinLength) {
    setCentreNameMinLengthError(
      "Centre name must be greater than 2 characters."
    );
  } else {
    setCentreNameMinLengthError("");
  }

  if (centreNames.includes(name.toLowerCase())) {
    setCentreNameError(
      "This centre already exists. Please choose another name."
    );
  } else if (centreNameError) {
    setCentreNameError("");
  }

  if (!centreId) {
    setCentreNameError("Centre ID missing");
  }

  setCentre({ name, centreId });
};

export const changeReportType = (
  reportType: string,
  centreReport: CentreReport,
  centreReports: CentreReport[],
  isFormPristine: boolean,
  setCentreReport: (report: CentreReport) => void,
  setIsFormPristine: (isPristine: boolean) => void,
  setIsSubmitted?: Dispatch<SetStateAction<boolean>>
): void => {
  if (centreReport.type === reportType) {
    return;
  }

  if (isFormPristine) {
    setIsFormPristine(false);
  }

  const correspondingReport = centreReports.find(
    item => item.type === reportType
  );

  if (correspondingReport) {
    setCentreReport(correspondingReport);
    if (setIsSubmitted) setIsSubmitted(false);
  }
};

export const getCentreReportTypeOptions = (response: CentreReport[]): {} => {
  return response.reduce(
    (o, key) => Object.assign(o, { [key.type]: key.name }),
    {}
  );
};

export const runToastr = (message: string): boolean => {
  if (message === "success") {
    toastr.success("Report type changed");
    return true;
  }
  toastr.error("Error while saving new report type, please try again");
  return false;
};

export const handleGetCentreReports = (
  response: CentreReport[],
  setCentreReports: (report: CentreReport[]) => void,
  setCentreReportTypeOptions: (options: {}) => void
): void => {
  setCentreReports(response);
  setCentreReportTypeOptions(getCentreReportTypeOptions(response));
};

export const clearSelectedCentre = (
  setCentre: (centre: { name: string; centreId: string }) => void,
  setCentreNameError: (name: string) => void,
  setCentreNameMinLengthError: (name: string) => void
): void => {
  setCentreNameMinLengthError("");
  setCentreNameError("");
  setCentre({ name: "", centreId: "" });
};
