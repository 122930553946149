// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { Centre } from "@cpsq/ui-interfaces";

// Stylesheets
import "./testUsageSearch.scss";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { OptionProps, ValueType } from "react-select";

interface Props {
  activeScope: Centre[];
  centres: Centre[];
  changeOption: (option: ValueType<Centre>) => void;
}

const filterOption = (
  option: { label: string; value: string; data: Centre },
  rawInput: string
): boolean =>
  Object.keys(option.data)
    .filter(key => {
      return key !== "clusteredIndexId" && key !== "id";
    })
    .some(key => {
      return (option.data[key as "centreId" | "name"] || "")
        .toLowerCase()
        .includes(rawInput.toLowerCase());
    });

const renderOption = (props: OptionProps<Centre>): ReactElement => {
  const { innerProps, innerRef } = props;

  return (
    <div ref={innerRef} {...innerProps} className="custom-option">
      <span className="bold">{props.data.name}</span>
    </div>
  );
};

const TestUsageSearch: FC<Props> = (props: Props): ReactElement => {
  return (
    <div className="react-select-container">
      <div className="react-select-input">
        <FontAwesomeIcon icon="search" />
        <Select
          className="react-select"
          classNamePrefix="react-select"
          components={{
            DropdownIndicator: (): null => null,
            IndicatorSeparator: (): null => null,
            Option: renderOption
          }}
          filterOption={filterOption}
          getOptionLabel={(option): string => option.name}
          getOptionValue={(option): string => option.name}
          isDisabled={!props.centres || !props.centres.length}
          isSearchable={true}
          value={props.activeScope}
          isMulti
          onChange={props.changeOption}
          options={[{ centreId: "", name: "All centres" }].concat(
            props.centres
          )}
          placeholder="Search for centre"
        />
      </div>
    </div>
  );
};

export default TestUsageSearch;
