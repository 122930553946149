// Core
import React, { ReactElement } from "react";
import { useParams, useHistory } from "react-router-dom";

// Components
import { Button } from "@cambridgeassessment/cambridge-ui";

// Assets
import CheckMark from "../../assets/images/check-mark.svg";

import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// StyleSheet
import "./groupConfirmation.scss";

interface GroupPath {
  id: string;
}

const GroupConfirmation = (): ReactElement => {
  const { id } = useParams<GroupPath>();
  const history = useHistory();

  return (
    <section className="group-confirmation center">
      <Typography variant="h5" className="beta bold">
        Group is now created.
      </Typography>
      <Typography variant="body1" className="gamma">
        To finish setting up the group, add respondents.
      </Typography>
      <img src={CheckMark} alt="check mark" />
      <Button
        color="primary"
        onClick={(): void => history.push("/centre-dashboard/")}
        data-testid="Return-to-groups"
      >
        Return to groups
      </Button>
      <Button
        color="primary"
        onClick={(): void => history.push(`/add-respondents/?groupId=${id}`)}
        startIcon={<FontAwesomeIcon icon="plus" />}
        data-testid="Add-respondents"
      >
        Add respondents
      </Button>
    </section>
  );
};

export default GroupConfirmation;
