// Core
import React, { ReactElement } from "react";

// Components
import { ButtonBar } from "@cpsq/ui-components";
import { Button, TextField, Radio } from "@cambridgeassessment/cambridge-ui";

// Interfaces
import { BaseEntity } from "@cpsq/api-types";
import { Group } from "@cpsq/ui-interfaces";

// Utils
import { Session } from "@cpsq/auth-frontend";
import Api from "../../utils/api";

// Vendor
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";

// StyleSheet
import "./newGroup.scss";

interface GroupEntity extends BaseEntity {
  id: string;
}

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  error: string;
  fieldErrors: {
    name?: string;
    access?: string;
  };
  failedSubmit: boolean;
  gender: string;
  group: Group | null;
  reportViewableByRespondent: boolean;
  title: string;
}

export class NewGroup extends React.Component<Props, State> {
  centreId = "";
  previousLocation: string;

  constructor(props: Props) {
    super(props);

    const group = this.getGroup(this.props.history);
    this.previousLocation = this.getPreviousLocation(group);

    this.state = {
      error: "",
      fieldErrors: {
        name: "",
        access: ""
      },
      failedSubmit: false,
      gender: "Male",
      group: group,
      reportViewableByRespondent: group
        ? group.reportViewableByRespondent
        : true,
      title: group ? group.title : ""
    };
  }

  componentDidMount(): void {
    this.centreId = Session.getSessionValue("centreId");

    document.body.className = "new-group";
  }

  componentWillUnmount(): void {
    document.body.className = "";
  }

  render(): ReactElement {
    return (
      <section className="new-group newGroupContainer">
        <Typography
          variant="h3"
          gutterBottom
          className="section-heading-form beta bold"
        >
          {this.state.group ? "Edit" : "New"} group
        </Typography>

        <TextField
          label="Group name:"
          id="edit-group-name"
          name="groupName"
          dataTestId="groupName"
          value={this.state.title}
          //@ts-ignore
          onChange={this.handleInputChange}
          validationType={this.state.fieldErrors.name ? "error" : undefined}
          helperText={this.state.fieldErrors.name}
          autoFocus
        />
        <div style={{ paddingTop: 20 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            className="section-heading-form beta bold"
          >
            Candidate access to report:
          </Typography>
          <Radio
            checked={this.state.reportViewableByRespondent}
            label="Candidates can access reports"
            onChange={(): void => this.handleAccessChange("true")}
          />
          <br />
          <Radio
            checked={!this.state.reportViewableByRespondent}
            label="Candidates cannot access reports"
            onChange={(): void => this.handleAccessChange("false")}
          />
        </div>
        <div style={{ paddingTop: 40 }}>
          <ButtonBar
            buttons={[
              <Button
                onClick={this.clickCancel}
                key="cancel"
                variant="text"
                color="default"
                className="button"
                data-testid="Cancel"
              >
                Cancel
              </Button>,
              <Button
                onClick={this.clickNext}
                disabled={!this.hasExistingGroupPropertiesChanged()}
                key="submit"
                type="submit"
                data-testid={this.state.group ? "Edit" : "Create"}
                className="button"
              >
                {this.state.group ? "Edit" : "Create"}
              </Button>
            ]}
          />
        </div>
      </section>
    );
  }

  clickCancel = (): void => {
    this.props.history.push(this.previousLocation);
  };

  clickNext = (): void => {
    if (this.validateTitle(this.state.title)) {
      if (this.state.group) {
        Api.editGroup(
          this.centreId,
          this.state.group.id,
          this.state.group.isArchived,
          this.state.title.trim(),
          this.state.group.createdDate,
          this.state.reportViewableByRespondent,
          this.handleNewGroup
        );
      } else {
        Api.createGroup(
          this.centreId,
          this.state.title.trim(),
          this.state.reportViewableByRespondent,
          this.handleNewGroup
        );
      }
    } else {
      this.setState({ failedSubmit: true });
    }
  };

  getGroup = (history: History): Group | null => {
    if (!history) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyLocationState: any = history.location.state;

    if (!historyLocationState || !historyLocationState.group) {
      return null;
    }

    return historyLocationState.group;
  };

  getPreviousLocation = (group: Group | null): string => {
    return group
      ? `/group-dashboard/?groupId=${group.id}`
      : "/centre-dashboard/";
  };

  handleInputChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const title = event.currentTarget.value;

    if (this.state.failedSubmit) {
      this.validateTitle(title);
    }

    this.setState({ title: title });
  };

  handleAccessChange = (value: string): void => {
    if (value !== "true" && value !== "false") return;

    this.setState({
      reportViewableByRespondent: value === "true"
    } as Pick<State, "reportViewableByRespondent">);
  };

  handleNewGroup = (result: { message: string; entity: GroupEntity }): void => {
    if (result.message === "success") {
      // New group creations should forward the user on to "add-responents"
      if (this.state.group) {
        this.props.history.push(this.previousLocation);
      } else {
        this.props.history.push(`/confirm-group/${result.entity.id}`);
      }
    } else {
      this.setState({
        failedSubmit: true,
        fieldErrors: {
          ...this.state.fieldErrors,
          name: result.message
        },
        error: result.message
      });
    }
  };

  hasExistingGroupPropertiesChanged(): boolean {
    const existingTitle = this.state.group?.title;
    const newTitle = this.state.title;

    const existingAccess = this.state.group?.reportViewableByRespondent;
    const newAccess = this.state.reportViewableByRespondent;

    if (!existingTitle || !existingAccess) {
      return true;
    }

    return (
      existingTitle?.toLowerCase() !== newTitle.toLowerCase() ||
      existingAccess !== newAccess
    );
  }

  validateTitle = (title: string): boolean => {
    title = title.trim();

    if (title) {
      if (title.length < 3) {
        this.setState({
          title: title,
          fieldErrors: {
            ...this.state.fieldErrors,
            name: "Name must have at least three characters"
          }
        });
        return false;
      } else {
        this.setState({
          title: title,
          fieldErrors: {
            ...this.state.fieldErrors,
            name: ""
          }
        });
        return true;
      }
    } else {
      this.setState({
        title: title,
        fieldErrors: {
          ...this.state.fieldErrors,
          name: "Name cannot be empty"
        }
      });
      return false;
    }
  };
}

export default withRouter(NewGroup);
