// Core
import React, { ReactElement } from "react";

// Components
import { Breadcrumbs } from "@cpsq/ui-components";
import GroupMultiSearch, {
  Option
} from "../../components/groupMultiSearch/groupMultiSearch";
import { Typography } from "@material-ui/core";

// Interfaces
import { Group } from "@cpsq/ui-interfaces";

// Stylesheets
import "./groupResults.scss";

// Utils
import { Session } from "@cpsq/auth-frontend";
import { underscoreToCamelCase } from "../../utils/string-helpers";

// Vendor
import Api from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";

import "toastr/build/toastr.min.css";
import RespondentResultsTable from "../../components/respondentResultsTable/respondentResultsTable";

const reportTitles: { [key: string]: string } = {
  higherEducationAdmissionsReport: "CPSQ - Higher Education",
  singaporeSkillsFrameworkAdmissions: "CPSQ - Singapore Skills"
};

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  centreId: string;
  centreName: string;
  urlGroupId: string;
  group: Group | null;
  groupReportTitle?: string;
  downloading: boolean;
  loadedGroups: Option[];
  selectedGroups: Option[];
  defaultSelectedGroups: Option[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadedResultsData: any;
}

export class GroupResults extends React.Component<Props, State> {
  componentDidMount(): void {
    const centreId = Session.getSessionValue("centreId");
    const centreName = Session.getSessionValue("centreName");
    const parsedQuery = queryString.parse(this.props.history.location.search);
    const groupId = parsedQuery.groupId as string;
    this.setState(
      {
        centreId,
        centreName,
        downloading: false,
        urlGroupId: groupId,
        loadedResultsData: [],
        selectedGroups: []
      },
      () => {
        Api.getGroups(centreId, (res: Group[]): void => {
          this.handleGetGroups(res, () => this.loadResults([groupId]));
        });
      }
    );
  }

  loadResults = (groupIds: string[]): void => {
    this.setState({ downloading: true });
    Api.getGroupResults(
      this.state.centreId,
      this.handleDataDownload,
      groupIds,
      (err: Error) => {
        console.warn(
          `There was an issue loading results data for ${groupIds}`,
          err
        );
        this.setState({ downloading: false });
      }
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDataDownload = (res: any): void => {
    this.setState({
      downloading: false,
      loadedResultsData: res
    });
  };

  handleGetGroups = (groups: Group[], callback?: Function): void => {
    const selectOptions = groups.map(group => {
      return { value: group.id, label: group.title };
    }) as Option[];

    let selectedGroups: Option[] = [];
    let groupFromUrl: Group | null = null;
    if (this.state.urlGroupId) {
      selectedGroups = selectOptions.filter(
        option => option.value === this.state.urlGroupId
      );
      groupFromUrl = groups.filter(
        group => String(group.id) === this.state.urlGroupId
      )[0];
    }
    let groupReportTitle: string | undefined = undefined;
    if (groupFromUrl) {
      groupReportTitle =
        reportTitles[underscoreToCamelCase(groupFromUrl.reportType)];
    }

    this.setState({
      selectedGroups: selectedGroups,
      loadedGroups: selectOptions,
      defaultSelectedGroups: selectedGroups,
      group: groupFromUrl,
      groupReportTitle: groupReportTitle
    });
    if (callback) callback();
  };

  onSelectionChange = (selected: Option[]): void => {
    if (!selected || selected.length === 0) {
      return this.setState({
        selectedGroups: [],
        loadedResultsData: []
      });
    }

    this.setState({
      selectedGroups: selected
    });

    const groupIds = selected.map(option => option.value);

    this.loadResults(groupIds);
  };

  clickBack = (): void => {
    if (this.state.urlGroupId) {
      this.props.history.push(
        `/group-dashboard/?groupId=${this.state.urlGroupId}`
      );
    } else {
      this.props.history.push("/centre-dashboard/");
    }
  };

  formatFilename = (filename: string): string => {
    return filename.replace(/\s/g, "-");
  };

  render(): ReactElement | null {
    if (!this.state) return null;

    const hasLoadedResults =
      this.state.loadedResultsData.length > 0 && !this.state.downloading;

    const hasNoCompletedCandidates =
      this.state.loadedResultsData.length === 0 &&
      this.state.selectedGroups.length > 0 &&
      !this.state.downloading;

    return (
      <section className="group-results">
        <header className="breakout">
          <Typography variant="h3" className="section-heading beta bold">
            Results
          </Typography>

          <Typography
            variant="subtitle2"
            className="section-sub-heading delta bold"
          >
            Review and analyse the results from your candidates across groups.
            Start by selecting a group.
          </Typography>
        </header>

        <Breadcrumbs
          items={[
            <span key="back" className="back" onClick={this.clickBack}>
              <FontAwesomeIcon icon="angle-left" size="lg" />
              {this.state.group ? this.state.group.title : "Back"}
            </span>
          ]}
        />

        <GroupMultiSearch
          defaultSelectedOptions={this.state.defaultSelectedGroups}
          handleOptionChange={this.onSelectionChange}
          options={this.state.loadedGroups}
          optionsHeading={this.state.groupReportTitle}
        />

        {hasLoadedResults && (
          <div className="margin-top-md">
            <Typography
              variant="h3"
              gutterBottom
              className="beta bold margin-bot-sm"
            >
              Respondent scores
            </Typography>
            <RespondentResultsTable
              data={this.state.loadedResultsData}
              csvFilename={`cpsq_group-results_${this.formatFilename(
                this.state.centreName
              )}`}
            />
          </div>
        )}

        {hasNoCompletedCandidates && (
          <Typography variant="h4">
            Sorry, no candidates have completed the assessment in your
            selection, please select other groups.
          </Typography>
        )}
      </section>
    );
  }
}

export default withRouter(GroupResults);
