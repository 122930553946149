// Utils
import { Api as ApiUtils } from "@cpsq/common-utils";
import { Centre } from "@cpsq/ui-interfaces";
import { NewCentre } from "../interfaces/new-centre";

export default class Api {
  static backend = process.env.REACT_APP_BACKEND;
  static utils = new ApiUtils(Api.backend);
  static getAuthToken: () => string | undefined;

  static async addRespondents(
    centreId: string,
    groupId: string,
    emailList: string[],
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}/groups/${groupId}/respondents/textUpload`,
      { emailList: emailList.join(" ") },
      callback,
      "post",
      false,
      Api.getAuthToken()
    );
  }

  static async updateCentre(
    centreId: string,
    centre: Centre,
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}`,
      {
        name: centre.name,
        defaultReportType: centre.defaultReportType,
        defaultNormGroup: centre.defaultNormGroup,
        defaultTestType: centre.defaultTestType
      },
      callback,
      "put",
      false,
      await Api.getAuthToken()
    );
  }

  static async createCentre(
    centres: NewCentre[],
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      "centres",
      { centres },
      callback,
      "post",
      false,
      Api.getAuthToken()
    );
  }

  static async findCentre(
    searchText: string,
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `findCentres?search=${searchText}`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static async deleteCentre(
    centreId: string,
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}`,
      {},
      callback,
      "delete",
      false,
      await Api.getAuthToken()
    );
  }

  static async createGroup(
    centreId: string,
    title: string,
    reportViewableByRespondent: boolean,
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}/groups`,
      {
        isArchived: false,
        title: title,
        reportViewableByRespondent,
        createdDate: Api.getDate(new Date(Date.now()))
      },
      callback,
      "post",
      false,
      Api.getAuthToken()
    );
  }

  static async deleteGroup(
    centreId: string,
    groupId: string,
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}/groups/${groupId}`,
      {},
      callback,
      "delete",
      false,
      Api.getAuthToken()
    );
  }

  static async downloadPdFReports(
    centreId: string,
    groupId: string,
    respondentIds: string[],
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}/groups/${groupId}/respondents/${respondentIds[0]}/report/download`,
      { respondentIds: respondentIds },
      callback,
      "post",
      true,
      Api.getAuthToken()
    );
  }

  static async editGroup(
    centreId: string,
    id: string,
    isArchived: boolean,
    title: string,
    createdDate: string,
    reportViewableByRespondent: boolean,
    callback: Function,
    lastPermittedTestDate?: string | null,
    isLastPermitedDateExpires?: boolean
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}/groups/${id}`,
      {
        isArchived,
        title,
        createdDate: Api.getDate(new Date(createdDate)),
        reportViewableByRespondent,
        lastPermittedTestDate,
        isLastPermitedDateExpires
      },
      callback,
      "put",
      false,
      Api.getAuthToken()
    );
  }

  static async getCentreReports(
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      "centreReports",
      callback,
      errorCallback,
      await Api.getAuthToken()
    );
  }

  static async getCentre(
    centreId: string,
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static async getCentres(
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData("centres", callback, errorCallback, Api.getAuthToken());
  }

  static async getReport(
    reportId: string,
    centreIds: string[] | string,
    startDate: string,
    endDate: string,
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    const centreIdsData = centreIds !== "" ? `&centreIds=${centreIds}` : "";
    const groupBy = startDate === "" && endDate === "" ? `&groupBy=YEARLY` : "";
    Api.utils.getData(
      `reports/${reportId}?startDate=${startDate}&endDate=${endDate}${centreIdsData}${groupBy}`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static async getReports(
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData("reports", callback, errorCallback, Api.getAuthToken());
  }

  static async getCentreFeatures(
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      "centre-features",
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static getDate(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  static async getGroup(
    centreId: string,
    groupId: string,
    callback: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}/groups/${groupId}`,
      callback,
      undefined,
      Api.getAuthToken()
    );
  }

  static async getGroupDashboard(
    centreId: string,
    groupId: string,
    callback: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}/groups/${groupId}/groupDashboard/`,
      callback,
      undefined,
      Api.getAuthToken()
    );
  }

  static async getGroups(
    centreId: string,
    callback: Function,
    errorCallback?: Function,
    incRespondents = true,
    incEmailSentSuccesses = true
  ): Promise<void> {
    let path = `centres/${centreId}/groups`;
    if (incRespondents) {
      path += "?incRespondents";
    }
    if (incEmailSentSuccesses) {
      path += (incRespondents ? "&" : "?") + "incEmailSentSuccesses";
    }

    Api.utils.getData(path, callback, errorCallback, Api.getAuthToken());
  }

  static async getGroupResults(
    centreId: string,
    callback: Function,
    groupIds: string[],
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}/results?groupIds=${groupIds}`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static async getRespondents(
    centreId: string,
    groupId: string,
    callback: Function,

    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}/groups/${groupId}/respondents`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static async getRespondentReport(
    centreId: string,
    groupId: string,
    respondentId: string,
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}/groups/${groupId}/respondents/${respondentId}/report`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static async triggerEmailDispatch(
    centreId: string,
    groupId: string,
    emailDataId: string,
    callback: Function,
    respondentIds?: string[] | null
  ): Promise<void> {
    const params: {
      respondentIds?: string[] | null;
    } = {
      respondentIds: respondentIds
    };
    Api.utils.postData(
      `centres/${centreId}/groups/${groupId}/emailDatas/${emailDataId}/send/`,
      params,
      callback,
      "post",
      true,
      Api.getAuthToken()
    );
  }

  /**
   * Send reminder emails to the referenced respondents
   * @param centreId
   * @param groupId
   * @param callback
   * @param respondentIds string[]
   */
  static async sendReminders(
    centreId: string,
    groupId: string,
    callback: Function,
    respondentIds?: string[] | null
  ): Promise<void> {
    const params: {
      respondentIds?: string[] | null;
    } = {
      respondentIds: respondentIds
    };
    Api.utils.postData(
      `centres/${centreId}/groups/${groupId}/emailDatas/remind`,
      params,
      callback,
      "post",
      true,
      Api.getAuthToken()
    );
  }

  static async updateEmailInvite(
    centreId: string,
    groupId: string,
    subject: string,
    details: string,
    emailId: string,
    callback: Function,
    emailType: string
  ): Promise<void> {
    const params: {
      subject: string;
      details: string;
      emailType: string;
    } = {
      subject: subject,
      details: details,
      emailType: emailType
    };

    if (emailId) {
      Api.utils.postData(
        `centres/${centreId}/groups/${groupId}/emailDatas/${emailId}`,
        params,
        callback,
        "put",
        false,
        Api.getAuthToken()
      );
    } else {
      Api.utils.postData(
        `centres/${centreId}/groups/${groupId}/emailDatas/`,
        params,
        callback,
        "post",
        false,
        Api.getAuthToken()
      );
    }
  }

  static async updateGroupSendDetails(
    centreId: string,
    groupId: string,
    date: string,
    sender: string,
    isLastPermitedDateExpires: boolean,
    callback: Function
  ): Promise<void> {
    const params: {
      fromName: string;
      lastPermittedTestDate?: string;
      isLastPermitedDateExpires: boolean;
    } = {
      fromName: sender,
      isLastPermitedDateExpires: isLastPermitedDateExpires
    };
    // empty string doesn't coerce to null type of date on the backend so we don't include it if its not a valid date.
    if (date) {
      params.lastPermittedTestDate = date;
    }

    Api.utils.postData(
      `centres/${centreId}/groups/${groupId}`,
      params,
      callback,
      "put",
      false,
      Api.getAuthToken()
    );
  }

  static async removeRespondents(
    centreId: string,
    groupId: string,
    callback: Function,
    respondentIds?: string[] | null
  ): Promise<void> {
    const params: {
      respondentIds?: string[] | null;
    } = {
      respondentIds: respondentIds
    };
    Api.utils.postData(
      `centres/${centreId}/groups/${groupId}/respondents`,
      params,
      callback,
      "delete",
      true,
      Api.getAuthToken()
    );
  }

  static async getApiKey(
    centreId: string,
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `centres/${centreId}/apikey`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }

  static async generateApiKey(
    centreId: string,
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}/apikey`,
      {},
      callback,
      "post",
      false,
      Api.getAuthToken()
    );
  }

  static async deleteApiKey(
    centreId: string,
    callback: Function
  ): Promise<void> {
    Api.utils.postData(
      `centres/${centreId}/apikey`,
      {},
      callback,
      "delete",
      false,
      Api.getAuthToken()
    );
  }

  static async getApiKeySpecification(
    callback: Function,
    errorCallback?: Function
  ): Promise<void> {
    Api.utils.getData(
      `apiSpecification`,
      callback,
      errorCallback,
      Api.getAuthToken()
    );
  }
}
