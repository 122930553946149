// Core
import React from "react";

// Components
import App from "./App";

// Utils
import { gtmConfig, OAuthConfig } from "./utils/config";
import { OAuthProvider } from "@cpsq/auth-frontend";

// Vendor
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFNSUtils from "@date-io/date-fns";
import { BrowserRouter as Router, Switch } from "react-router-dom";

//Styling
import { mainTheme } from "@cambridgeassessment/cambridge-ui";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { createMuiMainTheme } from "@cpsq/ui-components";

const tagManagerArgs = {
  gtmId: gtmConfig.gtmId
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFNSUtils}>
    <ThemeProvider theme={createMuiMainTheme(mainTheme)}>
      <CssBaseline />
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <OAuthProvider
            authority={OAuthConfig.authority}
            redirectUri={OAuthConfig.redirectUri}
            clientId={OAuthConfig.clientId}
            autoSignIn={false}
          >
            <App />
          </OAuthProvider>
        </Switch>
      </Router>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);
