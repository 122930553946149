/* eslint-disable @typescript-eslint/no-explicit-any */

// Core
import React, { ReactElement } from "react";

// Interfaces
import { ReportMonth } from "../../interfaces/report-month";

// Utils
import { getViewportSize } from "@cpsq/common-utils";
import { createMuiDatatableTheme } from "@cpsq/ui-components";

// Vendor
import { MuiThemeProvider, Theme } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { Table, TableRow, TableCell } from "@material-ui/core";
import MUIDataTable, {
  MUISortOptions,
  MUIDataTableOptions
} from "mui-datatables";
import { TestUsageByCentre } from "../../interfaces/testUsageByCentre";

const getMuiTheme = (viewportSize: string): Theme => {
  // @ts-ignore
  return createMuiDatatableTheme(viewportSize, { fixed: true, columns: 3 });
};

interface Props {
  activeScopeName: string;
  multipleScopes: boolean;
  report: ReportMonth[];
  isMultiYear?: boolean;
}

interface State {
  sortedColumn: MUISortOptions;
  viewportSize: string;
}

class TestUsageTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sortedColumn: {
        direction: "asc",
        name: "date"
      },
      viewportSize: getViewportSize(window.innerWidth)
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  render(): ReactElement {
    const options: MUIDataTableOptions = {
      download: true,
      selectToolbarPlacement: "none",
      filter: false,
      expandableRows: this.props.multipleScopes,
      expandableRowsOnClick: true,
      isRowExpandable: dataIndex =>
        !!this.props.report[dataIndex]?.testUsageByCentre?.length,
      expandableRowsHeader: false,
      renderExpandableRow: (rowData, { dataIndex }) =>
        this.renderCentreTestUsage(
          this.props.report[dataIndex]?.testUsageByCentre
        ),
      downloadOptions: {
        filename: `${this.props.activeScopeName} - test usage data.csv`,
        separator: ","
      },
      onColumnSortChange: this.sortColumn.bind(this),
      onDownload: this.downloadCSV.bind(this),
      pagination: false,
      print: false,
      sortOrder: this.state.sortedColumn,
      responsive: "standard",
      search: false,
      selectableRows: "none",
      viewColumns: false
    };

    return (
      <MuiThemeProvider theme={getMuiTheme(this.state.viewportSize)}>
        <MUIDataTable
          data={this.props.report}
          columns={this.getColumns()}
          options={options}
          title=""
        />
      </MuiThemeProvider>
    );
  }

  buildCSVBody(report: ReportMonth[]): string {
    const nonEmptyReports = report.filter(
      r => r.invitesSent > 0 || r.testsCompleted
    );

    return nonEmptyReports
      .map(reportMonth =>
        reportMonth.testUsageByCentre
          .filter(
            testUsage =>
              testUsage.invitesSent > 0 || testUsage.testsCompleted > 0
          )
          .map(
            testUsage =>
              [
                dayjs(reportMonth.date).format(
                  this.props.isMultiYear ? "YYYY" : "MMMM YYYY"
                ),
                testUsage.centreName,
                testUsage.invitesSent,
                testUsage.testsCompleted
              ].join(",") + "\r\n"
          )
          .join("")
      )
      .join("");
  }

  downloadCSV(): string | boolean {
    const header =
      ["Date", "Centre", "Invites sent", "Tests completed"].join(",") + "\r\n";

    const body = this.buildCSVBody(this.props.report);

    return body?.length > 0 ? header + body : false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): any[] {
    return [
      {
        name: "date",
        label: "Date",
        options: {
          customBodyRender: this.renderDateColumnBody,
          sortOrder:
            this.state.sortedColumn.name === "date"
              ? this.state.sortedColumn.direction
              : "none"
        }
      },
      {
        name: "invitesSent",
        label: "Invites sent",
        options: {
          sortOrder:
            this.state.sortedColumn.name === "invitesSent"
              ? this.state.sortedColumn.direction
              : "none"
        }
      },
      {
        name: "testsCompleted",
        label: "Tests completed",
        options: {
          sortOrder:
            this.state.sortedColumn.name === "testsCompleted"
              ? this.state.sortedColumn.direction
              : "none"
        }
      }
    ];
  }

  handleWindowResize(): void {
    const viewportSize = getViewportSize(window.innerWidth);

    if (this.state.viewportSize === viewportSize) {
      return;
    }

    this.setState({
      viewportSize
    });
  }

  renderDateColumnBody = (value: any): ReactElement => {
    let formattedDate = dayjs(value)
      .format("MMMM YYYY")
      .toString();

    formattedDate += dayjs(value).isSame(dayjs(), "month") ? " (current)" : "";

    return (
      <span className="bold">
        {this.props.isMultiYear ? value : formattedDate}
      </span>
    );
  };

  sortColumn(
    name: MUISortOptions["name"],
    direction: MUISortOptions["direction"]
  ): void {
    this.setState({
      sortedColumn: {
        direction,
        name
      }
    });
  }

  renderCentreTestUsage = (
    centreTestUsage: TestUsageByCentre[]
  ): ReactElement | undefined => {
    if (centreTestUsage?.length > 0) {
      return (
        <tr>
          <TableCell padding="checkbox" />
          <td colSpan={3}>
            <Table aria-label="Test usage table" className="test-usage">
              <tbody>
                {centreTestUsage.map(centreData => (
                  <TableRow key={centreData.centreName} className="test-usage">
                    <TableCell>{centreData.centreName}</TableCell>
                    <TableCell>{centreData.invitesSent}</TableCell>
                    <TableCell>{centreData.testsCompleted}</TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      );
    }
  };
}

export default TestUsageTable;
