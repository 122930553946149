// Core
import React, { Fragment, ReactElement } from "react";

// Components
import { LoadingSpinner, ReportMultiPage } from "@cpsq/ui-components";

// Interfaces
import { ReportModel } from "../../interfaces/report-model";

// Stylesheets
import "./viewResults.scss";

// Utils
import { Session } from "@cpsq/auth-frontend";
import { detectAdBlocker } from "@cpsq/network";
import { fetchReport } from "@cpsq/process-results";
import Api from "../../utils/api";

// Vendor
import { History } from "history";
import queryString from "query-string";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Group, Report } from "@cpsq/ui-interfaces";

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  group: Group;
  groupId: string;
  respondentId: string;
  reports: Report;
  respondentName: string;
  respondentEmail: string;
  testCompletionDate: string;
  downloading: boolean;
}

export class ViewResults extends React.Component<Props, State> {
  centreId = "";

  componentDidMount(): void {
    this.centreId = Session.getSessionValue("centreId");

    const parsedQuery = queryString.parse(this.props.history.location.search);
    const groupId = parsedQuery.groupId as string;
    const respondentId = parsedQuery.respondentId as string;

    this.setState({
      groupId: groupId,
      respondentId: respondentId,
      downloading: false
    });

    if (!groupId || !respondentId) {
      return this.props.history.push("/centre-dashboard/");
    }

    Api.getGroup(this.centreId, groupId, this.handleGetGroup);
  }

  render(): ReactElement {
    return (
      <Fragment>
        {this.state && this.state.downloading && (
          <LoadingSpinner classList="fullscreen" loadingText="Downloading..." />
        )}
        <span className="respondent">
          Report for:
          <div>{this.state?.respondentName || "Removed Name"}</div>
          <div>{this.state?.respondentEmail}</div>
        </span>
        <section className="reports">
          <div className="breadcrumbs">
            <span className="breadcrumb back" onClick={this.clickBack}>
              &lt; Back
            </span>
          </div>
          {this.renderReport()}
        </section>
      </Fragment>
    );
  }

  clickBack = (): void => {
    this.props.history.push(`/group-dashboard/?groupId=${this.state.groupId}`);
  };

  handleDownloadReport = async (): Promise<void> => {
    const adBlockerEnabled = await detectAdBlocker();

    if (adBlockerEnabled) {
      toastr.options = {
        positionClass: "toast-top-right",
        hideDuration: 200,
        timeOut: 3000
      };
      toastr.remove();
      toastr.error(
        "Please disable it then try downloading the report again",
        "An ad blocker has been detected"
      );
    } else {
      this.setState({ downloading: true });

      Api.downloadPdFReports(
        this.centreId,
        this.state.groupId,
        [this.state.respondentId],
        (response: Response) => {
          const a = document.createElement("a");
          document.body.appendChild(a);
          response.blob().then((blob: Blob) => {
            const objectURL = URL.createObjectURL(blob);
            a.href = objectURL;
            a.download = `CPSQ-${this.state.respondentName}.pdf`;
            a.click();
            window.URL.revokeObjectURL(objectURL);

            this.setState({ downloading: false });
          });
        }
      );
    }
  };

  handleGetGroup = (group: Group): void => {
    this.setState({ group });

    Api.getRespondentReport(
      this.centreId,
      this.state.groupId,
      this.state.respondentId,
      this.handleGetResults
    );
  };

  handleGetResults = (reportModel: ReportModel): void => {
    const {
      results,
      respondentName,
      respondentEmail,
      testCompletionDate
    } = reportModel;
    const reports = fetchReport(results, this.state.group.reportType);

    this.setState({
      reports,
      testCompletionDate,
      respondentName,
      respondentEmail
    });
  };

  renderReport(): ReactElement | null {
    if (!this.state || !this.state.reports) return null;
    const { reports, respondentName, testCompletionDate } = this.state;

    return (
      <div className="reportContainer">
        <ReportMultiPage
          reports={reports}
          respondentName={respondentName}
          testCompletionDate={testCompletionDate}
          isAdmin={true}
          handlePDFDownload={this.handleDownloadReport}
        />
      </div>
    );
  }
}

export default withRouter(ViewResults);
