// Core
import React, { FC, useCallback, useEffect, useState } from "react";

// Components
import { LoadingSpinner } from "@cpsq/ui-components";

// Utils
import Api from "../../utils/api";

// Vendor
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withRouter } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const ApiSpecification: FC = () => {
  const [apiSpecification, setApiSpecification] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleGetApiSpecification = useCallback(
    (apiSpecJson): void => {
      const servers = [{ url: process.env.REACT_APP_BACKEND }];
      apiSpecJson.servers = servers;

      setApiSpecification(apiSpecJson);
      setLoading(false);
    },
    [setApiSpecification, setLoading]
  );

  const handleGetApiSpecificationError = useCallback(
    (apiSpecGetError: Error): void => {
      setError(apiSpecGetError.message);
      setLoading(false);
    },
    [setError, setLoading]
  );

  useEffect(() => {
    Api.getApiKeySpecification(
      handleGetApiSpecification,
      handleGetApiSpecificationError
    );
  }, [handleGetApiSpecification, handleGetApiSpecificationError]);

  useEffect(() => {
    if (error) {
      toastr.error(error);
      setError(null);
    }
  }, [error, setError]);

  if (loading)
    return <LoadingSpinner loadingText="Loading API specification..." />;

  if (!apiSpecification) return null;

  return <SwaggerUI spec={apiSpecification} />;
};

export default withRouter(ApiSpecification);
