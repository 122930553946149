// Core
import React, { FC, ReactElement, useState } from "react";

// Assets
import CheckMark from "../../assets/images/check-mark.svg";

//Stylesheet
import "./singleCentreForm.scss";

// Components
import { Button } from "@cambridgeassessment/cambridge-ui";
import EditCentreForm from "../editCentreForm/editCentreForm";
import { Typography } from "@material-ui/core";

// Interfaces
import { CentreFormProps as Props } from "../../interfaces/centre-form-props";
import { ErrorHttpResponse } from "../../interfaces/http-responses/error-http-response";

// Utils
import { Session } from "@cpsq/auth-frontend";
import { isErrorHttpResponse } from "@cpsq/common-utils";
import Api from "../../utils/api";
import { Centre } from "@cpsq/ui-interfaces";

const SingleCentreForm: FC<Props> = (props: Props): ReactElement => {
  const [centre, setCentre] = useState<Centre>({ name: "", centreId: "" });
  const [centreNameError, setCentreNameError] = useState("");
  const [centreNameMinLengthError, setCentreNameMinLengthError] = useState("");
  const [createCentreError, setCreateCentreError] = useState("");

  const clickReturnToCentres = (): void => {
    props.history.push("/centres/");
  };

  const clickNext = (): void => {
    if (props.activeFormStep === props.totalFormSteps) {
      Api.createCentre(
        [
          {
            name: centre.name,
            centreId: centre.centreId,
            defaultReportType: props.centreReport.type,
            defaultNormGroup: props.centreReport.normGroup,
            defaultTestType: props.centreReport.testType
          }
        ],
        handleCreateCentres
      );
    } else {
      props.setActiveFormStep(props.activeFormStep + 1);
    }
  };

  // eslint-disable-next-line
  const handleCreateCentres = (response: any | ErrorHttpResponse): void => {
    if (!isErrorHttpResponse(response)) {
      const session = Session.getSession();

      if (session) {
        session.selectedCentreName = centre.name;
        session.selectedCentreId = response.entity.createdCentres[0].centreId;

        sessionStorage.setItem("CASession", JSON.stringify(session));
      }

      props.setFormComplete(true);
    } else {
      setCreateCentreError(
        "There was a problem creating the centre. Please try again."
      );
    }
  };

  const renderForm = (): ReactElement => {
    return (
      <EditCentreForm
        activeFormStep={props.activeFormStep}
        centreName={centre.name}
        centreNameError={centreNameError}
        centreNameMinLength={props.centreNameMinLength}
        centreNameMinLengthError={centreNameMinLengthError}
        centreNames={props.centreNames}
        centreReport={props.centreReport}
        centreReportTypeOptions={props.centreReportTypeOptions}
        centreReports={props.centreReports}
        clickBack={props.clickBack}
        clickNext={clickNext}
        createCentreError={createCentreError}
        formAction="create"
        formError={props.formError}
        isFormPristine={props.isFormPristine}
        setCentre={setCentre}
        setCentreNameError={setCentreNameError}
        setCentreNameMinLengthError={setCentreNameMinLengthError}
        setCentreReport={props.setCentreReport}
        setIsFormPristine={props.setIsFormPristine}
      />
    );
  };

  const renderCentreCreated = (): ReactElement => {
    return (
      <section className="centre-created center">
        <Typography variant="h5" className="beta bold">
          {centre.name} is now a centre
        </Typography>
        <img src={CheckMark} alt="check mark" />
        <Button
          color="primary"
          onClick={clickReturnToCentres}
          data-testid="Return to centres"
        >
          Return to centres
        </Button>
      </section>
    );
  };

  return (
    <section className="single-centre">
      {!props.formComplete && props.activeFormStep === 1 && (
        <>
          <Typography variant="h3" className="beta bold">
            Add centre
          </Typography>
          <Typography variant="body1" className="delta create-body">
            To add centre, begin by searching its name.
          </Typography>
          {renderForm()}
        </>
      )}
      {!props.formComplete && props.activeFormStep === 2 && (
        <>
          <Typography variant="h3" className="beta bold">
            Select the report type
          </Typography>
          <Typography variant="body1" className="delta create-body">
            This is the report that the centre and the respondents see.
          </Typography>
          {renderForm()}
        </>
      )}
      {props.formComplete && renderCentreCreated()}
    </section>
  );
};

export default SingleCentreForm;
