// Core
import React, { Fragment, ReactElement } from "react";

// Components
import { Breadcrumbs, Dropdown, LoadingSpinner } from "@cpsq/ui-components";
import RespondentsTable from "../../components/respondentsTable/respondentsTable";
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Typography, Tooltip } from "@material-ui/core";

// Interfaces
import { Group, Respondent } from "@cpsq/ui-interfaces";
import { SelectedRespondentRow } from "../../interfaces/selected-respondent-row";

// Stylesheets
import "./groupDashboard.scss";
import "./groupDashboard-responsive.scss";

// Utils
import { AuthContext, Session } from "@cpsq/auth-frontend";
import { getViewportSize, rerouteWithState } from "@cpsq/common-utils";
import { detectAdBlocker } from "@cpsq/network";
import Api from "../../utils/api";
import { getRespondentIdsByStatus } from "../../utils/group-helpers";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { History } from "history";
import Modal from "react-modal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import queryString from "query-string";

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  activeStatus: string;
  archiveModalOpen: boolean;
  removeRespondentsModalOpen: boolean;
  downloading: boolean;
  isLoadingGroupData: boolean;
  group: Group | null;
  isArchived: boolean;
  isCentreAdmin: boolean;
  respondentIdsPendingInvite: string[];
  selectedRespondentRowsByStatus: {
    notSent: SelectedRespondentRow[];
    sent: SelectedRespondentRow[];
    completed: SelectedRespondentRow[];
  };
  viewportSize: string;
}

type RespondentActiveStatus =
  | "notSentRespondents"
  | "sentRespondents"
  | "completedRespondents";

type ActiveStatus = "notSent" | "sent" | "completed";

export class GroupDashboard extends React.Component<Props, State> {
  centreId = "";
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    const group = this.getGroup(this.props.history);
    const respondentIdsPendingInvite = getRespondentIdsByStatus(
      group,
      "sendingRequest"
    );

    this.state = {
      activeStatus: "notSent",
      archiveModalOpen: false,
      removeRespondentsModalOpen: false,
      downloading: false,
      group,
      isCentreAdmin: false,
      isArchived: group ? group.isArchived : false,
      isLoadingGroupData: false,
      respondentIdsPendingInvite: respondentIdsPendingInvite,
      selectedRespondentRowsByStatus: {
        notSent: [],
        sent: [],
        completed: []
      },
      viewportSize: getViewportSize(window.innerWidth)
    };

    this.checkRespondent = this.checkRespondent.bind(this);
    this.clickAddRespondents = this.clickAddRespondents.bind(this);
    this.clickArchive = this.clickArchive.bind(this);
    this.clickBack = this.clickBack.bind(this);
    this.clickConfirmArchive = this.clickConfirmArchive.bind(this);
    this.clickEditGroupTitle = this.clickEditGroupTitle.bind(this);
    this.clickEditEmailTemplate = this.clickEditEmailTemplate.bind(this);
    this.clickGroupResults = this.clickGroupResults.bind(this);
    this.clickSendInvite = this.clickSendInvite.bind(this);
    this.clickViewResult = this.clickViewResult.bind(this);
    this.clickCloseArchiveModal = this.clickCloseArchiveModal.bind(this);
    this.handleGetGroup = this.handleGetGroup.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount(): void {
    this.centreId = this.context.isInternalAdmin
      ? Session.getSessionValue("selectedCentreId")
      : Session.getSessionValue("centreId");

    this.setState({
      isCentreAdmin: !this.context.isInternalAdmin
    });
    if (!this.state.group) {
      const values = queryString.parse(this.props.history.location.search);
      const groupId = values.groupId as string;

      if (!groupId) {
        this.props.history.push("/centre-dashboard/");
      }
      if (groupId) {
        this.loadGroupData(groupId);
      }
    }

    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  render(): ReactElement {
    return (
      <Fragment>
        {this.state && this.state.downloading && (
          <LoadingSpinner classList="fullscreen" loadingText="Downloading..." />
        )}
        <section className="group-dashboard">
          {this.renderViewModal(
            this.archiveModal(),
            this.state.archiveModalOpen,
            this.clickCloseArchiveModal
          )}
          {this.renderViewModal(
            this.removeRespondentModal(),
            this.state.removeRespondentsModalOpen,
            this.clickCloseRemoveRespondentModal
          )}
          {this.state.group && (
            <>
              {this.renderHeader(this.state.group, this.state.isArchived)}
              <div className="tabs-container">
                <div className="tabs-header">
                  {this.renderTabsNavigation(this.state.group)}
                  {this.renderTableHeaderButton(
                    this.state.activeStatus,
                    this.state.activeStatus === "notSent"
                      ? this.getRespondents(
                          this.state.group.notSentRespondents
                        ).concat(
                          this.getRespondents(
                            this.state.group.sendingRequestRespondents
                          )
                        )
                      : this.getRespondents(
                          this.state.group[
                            `${this.state.activeStatus}Respondents` as RespondentActiveStatus
                          ]
                        ),
                    this.state.selectedRespondentRowsByStatus[
                      this.state.activeStatus as ActiveStatus
                    ]
                  )}
                </div>
                <Button
                  onClick={(): void => {
                    this.state.group && this.loadGroupData(this.state.group.id);
                  }}
                  data-testid="Refresh table"
                  disabled={this.state.isLoadingGroupData}
                  startIcon={<FontAwesomeIcon icon="sync" />}
                  variant="text"
                  color="default"
                >
                  Refresh table
                </Button>
                {this.renderTabsBody(
                  this.state.activeStatus,
                  this.state.activeStatus === "notSent"
                    ? this.getRespondents(
                        this.state.group.notSentRespondents
                      ).concat(
                        this.getRespondents(
                          this.state.group.sendingRequestRespondents
                        )
                      )
                    : this.getRespondents(
                        this.state.group[
                          `${this.state.activeStatus}Respondents` as
                            | "notSentRespondents"
                            | "sentRespondents"
                            | "completedRespondents"
                        ]
                      )
                )}
              </div>
            </>
          )}
        </section>
      </Fragment>
    );
  }

  checkRespondent(selectedRespondentRows: SelectedRespondentRow[]): void {
    this.setState(prevState => ({
      selectedRespondentRowsByStatus: {
        ...prevState.selectedRespondentRowsByStatus,
        [this.state.activeStatus]: selectedRespondentRows
      }
    }));
  }

  clickAddRespondents(id: string): void {
    const notSentRespondentIds = getRespondentIdsByStatus(
      this.state.group,
      "notSent"
    );

    rerouteWithState(this.props.history, `/add-respondents/?groupId=${id}`, {
      notSentRespondentIds: notSentRespondentIds
    });
  }

  clickArchive(): void {
    this.toggleArchiveModalVisibility();
  }

  clickRemoveRespondent = (): void => {
    this.toggleRemoveRespondentsModalVisibility();
  };

  clickBack(): void {
    this.context.isInternalAdmin
      ? this.props.history.goBack()
      : this.props.history.push("/centre-dashboard/");
  }

  clickCloseArchiveModal(): void {
    this.toggleArchiveModalVisibility();
  }

  clickCloseRemoveRespondentModal = (): void => {
    this.toggleRemoveRespondentsModalVisibility();
  };

  clickConfirmArchive(
    centreId: string,
    group: Group | null,
    isArchived: boolean
  ): void {
    if (!isArchived) {
      this.toggleArchiveModalVisibility();
    }

    if (!group) {
      return;
    }

    Api.editGroup(
      centreId,
      group.id,
      !isArchived,
      group.title,
      group.createdDate,
      group.reportViewableByRespondent,
      () => {
        this.setState({
          isArchived: !isArchived
        });
      }
    );
  }

  clickDownloadReport = async (respondentIds: string[]): Promise<void> => {
    const adBlockerEnabled = await detectAdBlocker();

    if (adBlockerEnabled) {
      toastr.options = {
        positionClass: "toast-top-right",
        hideDuration: 200,
        timeOut: 3000
      };
      toastr.remove();
      toastr.error(
        "Please disable your ad blocker, then try downloading again",
        "An ad blocker has been detected"
      );
    } else {
      const groupId = this.state.group ? this.state.group.id : "0";
      const groupTitle = this.state.group
        ? "CPSQ-" + this.state.group.title
        : "";

      this.setState({ downloading: true });
      Api.downloadPdFReports(
        this.centreId,
        groupId,
        respondentIds,
        (response: Response) => {
          const a = document.createElement("a");
          document.body.appendChild(a);

          response.blob().then((blob: Blob): void => {
            const objectURL = URL.createObjectURL(blob);
            let downloadTitle = `${groupTitle}.zip`;
            a.href = objectURL;

            if (respondentIds.length === 1) {
              const singleRespondentDetails = this.getRespondentById(
                respondentIds[0]
              );

              if (!singleRespondentDetails) {
                throw new Error(
                  `Cannot retrieve details for respondent with id ${respondentIds[0]}`
                );
              }

              const respondentName =
                singleRespondentDetails.name || "removed-name";
              downloadTitle = `CPSQ-${respondentName}.pdf`;
            }

            a.download = downloadTitle;
            a.click();
            window.URL.revokeObjectURL(objectURL);
            this.setState({ downloading: false });
          });
        }
      );
    }
  };

  clickEditGroupTitle(): void {
    if (!this.state.group) {
      return;
    }

    rerouteWithState(this.props.history, "/new-group", {
      group: this.state.group
    });
  }

  clickEditEmailTemplate(): void {
    if (!this.state.group) {
      return;
    }

    rerouteWithState(
      this.props.history,
      `/create-invites/?groupId=${this.state.group.id}`,
      {
        group: this.state.group
      }
    );
  }

  clickGroupResults(id: string): void {
    this.props.history.push(`/group-results?groupId=${id}`);
  }

  clickRetryInvite = (respondentIds?: string[]): void => {
    if (!this.state.group || !this.state.group.id) {
      throw new Error("No group found in the state");
    }
    if (!respondentIds?.length) {
      throw new Error("No respondent ids provided");
    }

    const inviteEmailData =
      this.state.group.emailDatas &&
      this.state.group.emailDatas.find(
        emailData => emailData.emailType.name === "invite"
      );

    if (!inviteEmailData) {
      throw new Error("Missing email data");
    }

    Api.triggerEmailDispatch(
      this.centreId,
      this.state.group?.id,
      inviteEmailData.id,
      this.handleRetrySend,
      respondentIds
    );
  };

  clickSendInvite(respondentIds: string[]): void {
    const groupId = this.state.group ? this.state.group.id : 0;

    rerouteWithState(this.props.history, `/create-invites?groupId=${groupId}`, {
      respondentIds: respondentIds
    });
  }

  clickSendReminder = (respondentIds?: string[]): void => {
    if (!this.state.group || !this.state.group.id) {
      throw new Error("No group found in the state");
    }

    if (!respondentIds?.length) {
      throw new Error("No respondent ids provided");
    }

    Api.sendReminders(
      this.centreId,
      this.state.group?.id,
      this.handleReminderSend,
      respondentIds
    );
  };

  clickTab(tabName: string): void {
    this.setState({
      activeStatus: tabName
    });
  }

  clickViewResult(respondentId: string): void {
    const groupId = this.state.group ? this.state.group.id : null;

    if (!groupId) {
      throw new Error("Group id is not available");
    }

    this.props.history.push(
      `/results?groupId=${groupId}&respondentId=${respondentId}`
    );
  }

  getFriendlyStatus(status: string): string {
    switch (status) {
      case "completed":
        return "Complete";
      case "notSent":
        return "Not sent";
      default:
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
  }

  getGroup(history: History): Group | null {
    if (!history) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyLocationState: any = history.location.state;

    if (!historyLocationState || !historyLocationState.group) {
      return null;
    }

    return historyLocationState.group;
  }

  getRespondentById(respondentId: string): Respondent | null {
    if (
      !this.state.group ||
      !this.state.group.respondents ||
      this.state.group.respondents.length === 0
    ) {
      return null;
    }

    return this.state.group.respondents.filter(
      respondent => respondent.id === respondentId
    )[0];
  }

  getRespondents(respondents: Respondent[] | undefined): Respondent[] {
    if (!respondents) {
      return [];
    }

    return respondents;
  }

  getRespondentsCount(respondents: Respondent[] | undefined): number {
    if (!respondents) {
      return 0;
    }

    return respondents.length;
  }

  getSelectedRespondentIds(
    selectedRespondentRows: SelectedRespondentRow[]
  ): string[] {
    return selectedRespondentRows.map(
      selectedRespondentRow => selectedRespondentRow.respondentId
    );
  }

  getSelectedRespondentRowIndexes(
    selectedRespondents: SelectedRespondentRow[]
  ): number[] {
    return selectedRespondents.map(
      selectedRespondent => selectedRespondent.rowIndex
    );
  }

  handleGetGroup(group: Group): void {
    const respondentIdsPendingInvite = getRespondentIdsByStatus(
      group,
      "sendingRequest"
    );

    this.setState({
      group,
      isArchived: group.isArchived,
      isLoadingGroupData: false,
      respondentIdsPendingInvite
    });

    this.props.history.replace({
      state: {
        group
      },
      pathname: `/group-dashboard/?groupId=${group.id}`
    });
  }

  handleReminderSend = (res: Response): void => {
    if (!this.state.group || !this.state.group.id) {
      throw new Error("No group found in the state");
    }

    if (!res.ok) {
      toastr.error("There was an issue sending reminders to the respondent(s)");
      return;
    }

    toastr.success("Reminder(s) sent");

    this.setState({
      selectedRespondentRowsByStatus: {
        ...this.state.selectedRespondentRowsByStatus,
        sent: []
      }
    });

    this.loadGroupData(this.state.group.id);
  };

  handleRetrySend = (res: Response): void => {
    if (!this.state.group || !this.state.group.id) {
      throw new Error("No group found in the state");
    }

    if (!res.ok) {
      toastr.error(
        "There was an issue retrying sending the email to the respondent(s)"
      );

      return;
    }

    toastr.success("Retry sent");

    this.loadGroupData(this.state.group.id);
  };

  handleWindowResize(): void {
    const viewportSize = getViewportSize(window.innerWidth);

    if (this.state.viewportSize === viewportSize) {
      return;
    }

    this.setState({
      viewportSize
    });
  }

  loadGroupData = (groupId: string): void => {
    if (!groupId) throw new Error("No Group Id provided");

    this.setState({ isLoadingGroupData: true });

    Api.getGroupDashboard(this.centreId, groupId, this.handleGetGroup);
  };

  renderHeader(group: Group, isArchived: boolean): ReactElement {
    let lastPermittedTestDate = "Not expiring";

    if (group.lastPermittedTestDate && group.isLastPermitedDateExpires) {
      lastPermittedTestDate = dayjs(group.lastPermittedTestDate).format(
        "DD MMM YYYY"
      );
    }

    return (
      <header className="breakout" data-testid="group-dashboard-header">
        <Breadcrumbs
          items={[
            <span key="back" className="back" onClick={this.clickBack}>
              <FontAwesomeIcon icon="angle-left" size="lg" /> Back
            </span>
          ]}
        />
        <div className="column-one">
          <Typography
            variant="h3"
            gutterBottom
            className="beta bold"
            data-testid="group-title"
          >
            {group.title}
          </Typography>
          <ul className="group-statistics">
            <li>
              <span className="epsilon bold">Respondents</span>
              <span
                className="gamma bold"
                data-testid="total-respondents-count"
              >
                {group.respondents ? group.respondents.length : 0}
              </span>
            </li>
            <li>
              <span className="epsilon bold">Completed</span>
              <span
                className="gamma bold"
                data-testid="completed-respondents-count"
              >
                {group.completedRespondents &&
                group.respondents &&
                group.respondents.length
                  ? Math.ceil(
                      (group.completedRespondents.length /
                        group.respondents.length) *
                        100
                    )
                  : 0}
                {" %"}
              </span>
            </li>
            <li>
              <span className="epsilon bold">Date created</span>
              <span className="gamma bold" data-testid="group-date-created">
                {dayjs(group.createdDate).format("DD MMM YYYY")}
              </span>
            </li>
            <li>
              <span className="epsilon bold">Invite expiration date</span>
              <span className="gamma bold" data-testid="group-date-created">
                {lastPermittedTestDate}
              </span>
            </li>
          </ul>
          Respondents {group.reportViewableByRespondent ? "can" : "cannot"} see
          reports
        </div>
        {this.state.isCentreAdmin && (
          <div className="column-two">
            <Button
              onClick={(): void => {
                this.clickAddRespondents(group.id);
              }}
              disabled={!Object.keys(group).length || isArchived}
              startIcon={<FontAwesomeIcon icon="plus" />}
              color="default"
              data-testid="Add respondents"
            >
              Add respondents
            </Button>
            <Button
              onClick={(): void => {
                this.clickGroupResults(group.id);
              }}
              disabled={!Object.keys(group).length}
              startIcon={<FontAwesomeIcon icon="chart-bar" />}
              color="default"
              data-testid="Group results"
            >
              Group results
            </Button>
            <Dropdown
              xPosition={
                this.state.viewportSize === "mobile" ? "right" : "left"
              }
              testId="group-dashboard-options-dropdown"
              toggle={<div className="bold">Options</div>}
              items={[
                <span
                  className="edit-group-title"
                  data-testid="edit-group-title"
                  key="editGroupTitle"
                  onClick={this.clickEditGroupTitle}
                >
                  <FontAwesomeIcon icon="pen" />
                  Edit group settings
                </span>,
                <span
                  className="edit-email-template"
                  data-testid="edit-email-template"
                  key="editEmailTemplate"
                  onClick={this.clickEditEmailTemplate}
                >
                  <FontAwesomeIcon icon="pen" />
                  Edit email template
                </span>,
                <span
                  className={isArchived ? "unarchive bold" : "archive bold"}
                  data-testid={isArchived ? "unarchive-group" : "archive-group"}
                  key="archiveGroup"
                  onClick={(): void =>
                    isArchived
                      ? this.clickConfirmArchive(
                          this.centreId,
                          group,
                          isArchived
                        )
                      : this.clickArchive()
                  }
                >
                  <FontAwesomeIcon
                    icon={isArchived ? "stopwatch" : "archive"}
                  />
                  {isArchived ? "Unarchive" : "Archive"} group
                </span>
              ]}
            />
          </div>
        )}
      </header>
    );
  }

  renderTableHeaderButton = (
    activeStatus: string,
    respondents: Respondent[] | undefined,
    selectedRespondentRows: SelectedRespondentRow[]
  ): ReactElement | undefined => {
    let action, text, isButtonDisabled;

    if (!this.state.isCentreAdmin) {
      return;
    }

    const plural = selectedRespondentRows.length === 1 ? "" : "s";

    isButtonDisabled =
      !respondents ||
      !respondents.length ||
      !this.state.group ||
      !Object.keys(this.state.group).length ||
      this.state.isLoadingGroupData;

    switch (activeStatus) {
      case "completed":
        action = (): void => {
          this.clickDownloadReport(
            selectedRespondentRows.length
              ? this.getSelectedRespondentIds(selectedRespondentRows)
              : getRespondentIdsByStatus(this.state.group, "completed")
          );
        };

        text = selectedRespondentRows.length
          ? `Download ${selectedRespondentRows.length} report${plural}`
          : "Download all reports";
        break;
      case "notSent":
        const selectedRespondentRowsLength = selectedRespondentRows.length;

        action = (): void => {
          this.clickSendInvite(
            selectedRespondentRowsLength
              ? this.getSelectedRespondentIds(selectedRespondentRows)
              : getRespondentIdsByStatus(this.state.group, "notSent")
          );
        };

        text = selectedRespondentRowsLength
          ? `Invite ${selectedRespondentRowsLength} respondent${plural}`
          : "Invite all respondents";

        isButtonDisabled =
          isButtonDisabled ||
          this.state.isArchived ||
          !respondents?.filter(r => r.email).length;
        break;
      case "sent":
        action = (): void => {
          // remind all respondents in the table, or only those selected
          if (
            !selectedRespondentRows.length ||
            selectedRespondentRows.length === 0
          ) {
            return this.clickSendReminder(
              respondents?.filter(r => r.email)?.map(r => r.id)
            );
          }
          this.clickSendReminder(
            this.getSelectedRespondentIds(selectedRespondentRows)
          );
        };

        text = selectedRespondentRows.length
          ? `Remind ${selectedRespondentRows.length} respondent${plural}`
          : "Remind all respondents";

        isButtonDisabled =
          isButtonDisabled ||
          this.state.isArchived ||
          !respondents?.filter(r => r.email).length;
        break;
      default:
        return;
    }

    const isStartedRepondentsSelected =
      activeStatus === "sent" &&
      respondents
        ?.filter(r =>
          this.state.selectedRespondentRowsByStatus.sent.find(
            s => s.respondentId === r.id
          )
        )
        .some(r => r.startDate);

    const isRemovedRespondentsSelected =
      activeStatus !== "completed" &&
      respondents
        ?.filter(r =>
          this.state.selectedRespondentRowsByStatus[activeStatus].find(
            s => s.respondentId === r.id
          )
        )
        .some(r => !r.email);

    return (
      <div>
        {(activeStatus === "notSent" || activeStatus === "sent") && (
          <Tooltip
            title="Some of the selected respondents have already started the survey"
            disableFocusListener={
              !selectedRespondentRows.length || !isStartedRepondentsSelected
            }
            disableHoverListener={
              !selectedRespondentRows.length || !isStartedRepondentsSelected
            }
            disableTouchListener={
              !selectedRespondentRows.length || !isStartedRepondentsSelected
            }
          >
            <span>
              <Button
                onClick={this.clickRemoveRespondent}
                disabled={
                  !selectedRespondentRows.length || isStartedRepondentsSelected
                }
                startIcon={<FontAwesomeIcon icon="trash" />}
                variant="text"
                color="destructive"
                data-testid={`Remove ${selectedRespondentRows.length} respondents`}
              >
                {`Remove ${selectedRespondentRows.length} respondents`}
              </Button>
            </span>
          </Tooltip>
        )}

        <Tooltip
          title="Can't invite or send reminders to removed respondents"
          disableFocusListener={
            !selectedRespondentRows.length || !isRemovedRespondentsSelected
          }
          disableHoverListener={
            !selectedRespondentRows.length || !isRemovedRespondentsSelected
          }
          disableTouchListener={
            !selectedRespondentRows.length || !isRemovedRespondentsSelected
          }
        >
          <span>
            <Button
              onClick={action}
              data-testid={text}
              disabled={isButtonDisabled || isRemovedRespondentsSelected}
              startIcon={<FontAwesomeIcon icon="envelope" />}
              variant="text"
              color="primary"
            >
              {text}
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  };

  renderViewModal(
    component: ReactElement,
    openModal: boolean,
    closeModelFunction: Function
  ): ReactElement {
    return (
      <Modal
        ariaHideApp={false}
        className="group-dashboard-modal"
        isOpen={openModal}
        onRequestClose={(): void => closeModelFunction()}
        overlayClassName="group-dashboard-modal-overlay"
        contentLabel="Example Modal"
      >
        {component}
      </Modal>
    );
  }

  archiveModal(): ReactElement {
    return (
      <Fragment>
        <div className="modal-header">
          <FontAwesomeIcon icon="archive" size="lg" />
        </div>
        <div className="modal-body">
          <Typography variant="subtitle1" gutterBottom className="delta bold">
            Archive this group?
          </Typography>
          <Typography variant="body1">
            Respondents cannot complete a questionnaire while a group is
            archived.
          </Typography>
          <Typography variant="body1">
            Results are still available for archived groups.
          </Typography>
        </div>
        <div className="modal-footer">
          <Button
            onClick={this.clickCloseArchiveModal}
            variant="text"
            color="default"
            data-testid="Cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={this.clickConfirmArchive.bind(
              this,
              this.centreId,
              this.state.group,
              this.state.isArchived
            )}
            color="primary"
            data-testid="Archive"
          >
            Archive
          </Button>
        </div>
      </Fragment>
    );
  }

  clickConfirmRemoveRespondents = (
    centreId: string,
    group: Group | null,
    respondentIds: string[]
  ): void => {
    Api.removeRespondents(
      centreId,
      group?.id,
      () => {
        this.clickRemoveRespondent();
        this.setState(
          {
            selectedRespondentRowsByStatus: {
              ...this.state.selectedRespondentRowsByStatus,
              notSent: [],
              sent: []
            }
          },
          () => {
            this.state.group && this.loadGroupData(this.state.group.id);
          }
        );
      },
      respondentIds
    );
  };

  removeRespondentModal(): ReactElement {
    return (
      <Fragment>
        <div className="modal-header">
          <FontAwesomeIcon icon="trash" size="lg" />
        </div>
        <div className="modal-body">
          <Typography variant="subtitle1" gutterBottom className="delta bold">
            Remove{" "}
            {this.state.activeStatus === "notSent"
              ? this.state.selectedRespondentRowsByStatus.notSent.length
              : this.state.selectedRespondentRowsByStatus.sent.length}{" "}
            respondents?
          </Typography>
          <Typography variant="body1">
            They will no longer be able to complete CPSQ.
          </Typography>
          <Typography variant="body1">This cannot be undone.</Typography>
        </div>
        <div className="modal-footer">
          <Button
            onClick={(): void => this.clickCloseRemoveRespondentModal()}
            variant="text"
            color="default"
            data-testid="Cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={(): void =>
              this.clickConfirmRemoveRespondents(
                this.centreId,
                this.state.group,
                this.getSelectedRespondentIds(
                  this.state.activeStatus === "notSent"
                    ? this.state.selectedRespondentRowsByStatus.notSent
                    : this.state.selectedRespondentRowsByStatus.sent
                )
              )
            }
            color="primary"
            data-testid="Remove"
          >
            Remove
          </Button>
        </div>
      </Fragment>
    );
  }

  renderTabsBody(
    activeStatus: string,
    respondents: Respondent[] | undefined
  ): ReactElement {
    return (
      <div className="tabs-body">
        <div
          className={
            respondents && respondents.length
              ? "respondents-table has-respondents"
              : "respondents-table no-respondents"
          }
        >
          <RespondentsTable
            activeStatus={this.state.activeStatus}
            checkRespondent={this.checkRespondent}
            clickSendInvite={this.clickSendInvite}
            clickRetryInvite={this.clickRetryInvite}
            clickSendReminder={this.clickSendReminder}
            clickViewResult={this.clickViewResult}
            isCentreAdmin={this.state.isCentreAdmin}
            groupId={this.state.group ? this.state.group.id : undefined}
            groupIsArchived={this.state.isArchived}
            history={this.props.history}
            isLoadingGroupData={this.state.isLoadingGroupData}
            respondentIdsPendingInvite={this.state.respondentIdsPendingInvite}
            respondents={respondents || []}
            selectedRespondentRowIndexes={this.getSelectedRespondentRowIndexes(
              this.state.selectedRespondentRowsByStatus[
                this.state.activeStatus as "notSent" | "sent" | "completed"
              ]
            )}
          />
        </div>
      </div>
    );
  }

  renderTabsNavigation(group: Group): ReactElement | undefined {
    if (!group || !group.respondents) {
      return;
    }

    const keys = ["notSent", "sent", "completed"];

    return (
      <div className="tabs-navigation">
        {keys.map(key => {
          return (
            <Button
              onClick={(): void => this.clickTab(key)}
              color={this.state.activeStatus === key ? "primary" : "default"}
              data-testid={this.getFriendlyStatus(key)}
              className="button"
              key={key}
            >
              {
                <>
                  <span className="tab-name">
                    {this.getFriendlyStatus(key)}
                  </span>
                  <span className="respondent-count gamma">
                    {this.getRespondentsCount(
                      group[
                        `${key}Respondents` as
                          | "notSentRespondents"
                          | "sentRespondents"
                          | "completedRespondents"
                      ]
                    ) +
                      (key === "notSent"
                        ? this.getRespondentsCount(
                            group.sendingRequestRespondents
                          )
                        : 0)}
                  </span>
                </>
              }
            </Button>
          );
        })}
      </div>
    );
  }

  toggleArchiveModalVisibility(): void {
    this.setState({
      archiveModalOpen: !this.state.archiveModalOpen
    });
  }

  toggleRemoveRespondentsModalVisibility(): void {
    this.setState({
      removeRespondentsModalOpen: !this.state.removeRespondentsModalOpen
    });
  }
}

export default withRouter(GroupDashboard);
