// Core
import React, { ReactElement, ComponentType } from "react";

// Components
import { ButtonBar } from "@cpsq/ui-components";
import {
  TextField,
  DateInput,
  Button,
  Checkbox
} from "@cambridgeassessment/cambridge-ui";
import { Typography, TextFieldProps } from "@material-ui/core";

// Interfaces
import { BaseEntity } from "@cpsq/api-types";
import { Group } from "@cpsq/ui-interfaces";

// Utils
import { Session } from "@cpsq/auth-frontend";
import Api from "../../utils/api";
import { getHumanDateString } from "../../utils/date-helpers";
import { isFieldEmpty } from "../../utils/form-helpers";
import { getRespondentIdsByStatus } from "../../utils/group-helpers";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import queryString from "query-string";
import Modal from "react-modal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

// Stylesheets
import "./createInvites.scss";

import HttpStatus from "http-status-codes";

interface EmailEntity extends BaseEntity {
  id: string;
}

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  date: Date | null;
  isLastPermitedDateExpires: boolean;
  details: string;
  error: string;
  fieldErrors: {
    subject?: string;
    date?: string;
    details?: string;
    sender?: string;
  };
  groupId: string;
  emailDataId: string;
  previewOpen: boolean;
  respondentIds: string[];
  sendAfterSubmit: boolean;
  sender: string;
  subject: string;
  emailType: string;
  reportViewableByRespondent: boolean;
  submitting: boolean;
  isFromAddRespondent: boolean;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    padding: "20px"
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.37)"
  }
};

const DateTextField = (props: ComponentType<TextFieldProps>): ReactElement => (
  <TextField
    id="date-input"
    optional={true}
    dataTestId="date"
    label="Respondent to complete by"
    {...props}
  />
);

export class CreateInvites extends React.Component<Props, State> {
  centreId = "";

  constructor(props: Props) {
    super(props);

    const respondentIds = this.getRespondentIds(this.props.history);
    const isFromAddRespondent = this.checkFromURL(this.props.history);
    const values = queryString.parse(this.props.history.location.search);
    let groupId = values.groupId as string;

    if (!groupId) {
      groupId = "";

      this.props.history.push("/centre-dashboard/");
    }

    this.state = {
      date: null,
      isLastPermitedDateExpires: false,
      details: "",
      error: "",
      fieldErrors: {},
      groupId,
      emailDataId: "",
      previewOpen: false,
      respondentIds,
      sendAfterSubmit: false,
      sender: "",
      subject: "",
      emailType: "invite",
      reportViewableByRespondent: true,
      submitting: false,
      isFromAddRespondent
    };

    this.handleGetGroup = this.handleGetGroup.bind(this);
    this.handleGetGroupDashboard = this.handleGetGroupDashboard.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount(): void {
    // doesn't work with tests for some reason
    if (process.env.JEST_WORKER_ID === undefined) {
      Modal.setAppElement(".wrap");
    }

    this.centreId = Session.getSessionValue("centreId");

    if (this.state.groupId) {
      Api.getGroup(this.centreId, this.state.groupId, this.handleGetGroup);
    }

    if (!this.state.respondentIds.length) {
      if (this.state.groupId) {
        Api.getGroupDashboard(
          this.centreId,
          this.state.groupId,
          this.handleGetGroupDashboard
        );
      }
    }
  }

  render(): ReactElement {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      <>
        <section>
          <Typography
            variant="h3"
            gutterBottom
            className="section-heading-form beta bold"
          >
            Create email
          </Typography>

          <DateInput
            name="date-input"
            minDate={tomorrow}
            maxDate="01/01/9999"
            invalidDateMessage="Invalid date"
            minDateMessage="Respondent should have at least one day to complete questionaire"
            onChange={this.handleDateChange}
            value={this.state.date}
            validationType={this.state.fieldErrors.date ? "error" : undefined}
            TextFieldComponent={DateTextField as ComponentType<TextFieldProps>}
          />
          <div className="checkbox-small">
            <Checkbox
              checked={this.state.isLastPermitedDateExpires}
              onChange={(): void =>
                this.setState({
                  isLastPermitedDateExpires: !this.state
                    .isLastPermitedDateExpires
                })
              }
              hintText="Have the link expire on this day"
              disabled={this.state.date ? false : true}
            />
          </div>

          <TextField
            label="Sender"
            id="sender"
            name="sender"
            value={this.state.sender}
            onChange={this.handleInputChange}
            validationType={this.state.fieldErrors.sender ? "error" : undefined}
            helperText={this.state.fieldErrors.sender}
          />

          <TextField
            label="Subject"
            id="subject"
            name="subject"
            value={this.state.subject}
            onChange={this.handleInputChange}
            validationType={
              this.state.fieldErrors.subject ? "error" : undefined
            }
            helperText={this.state.fieldErrors.subject}
          />

          <Typography variant="subtitle2">Body</Typography>
          <br />

          <Typography paragraph variant="body1">
            Please complete the Cambridge Personal Styles Questionnaire (CPSQ)
            {this.state.date !== null &&
              " by " + getHumanDateString(this.state.date)}
            . It will take you about 30 minutes.
          </Typography>

          <TextField
            id="details"
            name="details"
            label="Additional info"
            rows={6}
            value={this.state.details}
            onChange={this.handleInputChange}
            optional={true}
            multiline
          />

          <Typography paragraph variant="body2" style={{ fontStyle: "italic" }}>
            [Link to complete questionnaire]
          </Typography>
          <Typography paragraph variant="body2">
            Thank you,
          </Typography>
          <Typography paragraph variant="body2">
            {this.state.sender}
          </Typography>

          {this.state.isFromAddRespondent && (
            <>
              <br />
              <Typography
                paragraph
                variant="body1"
                data-testid="send-warning-message"
              >
                When you click “Send Now” an invite will be sent to all
                respondents currently in the Not Sent status. To send it only to
                a selection of respondents click “Save draft and exit” and
                select the users within the table you would like to send an
                invite.
              </Typography>
            </>
          )}

          <ButtonBar
            buttons={[
              <Button
                onClick={this.clickCancel}
                key="cancel"
                variant="text"
                color="default"
                className="button"
                data-testid="Cancel"
              >
                Cancel
              </Button>,
              <Button
                onClick={(): void => this.togglePreview(true)}
                key="preview"
                color="default"
                className="button"
                data-testid="Preview"
              >
                Preview
              </Button>,
              <Button
                onClick={this.clickSave}
                key="save"
                color="default"
                type="submit"
                className="button"
                data-testid="Save draft and exit"
              >
                Save draft and exit
              </Button>,
              <Button
                onClick={this.clickSend}
                key="send"
                color="primary"
                type="submit"
                className="button"
                data-testid="Send now"
                disabled={!Boolean(this.state.respondentIds?.length)}
              >
                Send now
              </Button>
            ]}
          />
        </section>

        <Modal
          isOpen={this.state.previewOpen}
          onRequestClose={(): void => this.togglePreview(false)}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <div
            className="modal-close"
            onClick={(): void => this.togglePreview(false)}
          >
            <FontAwesomeIcon className="icon" icon="times" size="lg" />
          </div>
          <div className="modal-body">
            <div className="preview-subject-line beta semibold">
              {this.state.subject}
            </div>
            <div className="preview-from-line gamma">{this.state.sender}</div>
            <Typography paragraph variant="body1">
              Please complete the Cambridge Personal Styles Questionnaire (CPSQ)
              {this.state.date !== null &&
                " by " + getHumanDateString(this.state.date)}
              . It will take you about 30 minutes.
            </Typography>
            {this.state.details.split(/\r?\n/).map((detail, i) => {
              return (
                <Typography paragraph variant="body2" key={i}>
                  {detail}
                </Typography>
              );
            })}
            <Typography paragraph variant="body2" className="link">
              Take CPSQ
            </Typography>
            {this.state.reportViewableByRespondent && (
              <Typography paragraph variant="body2">
                You can also use this link to view your report after you have
                finished.
              </Typography>
            )}
            <Typography paragraph variant="body2">
              Thank you,
            </Typography>
            <Typography paragraph variant="body2">
              {this.state.sender}
            </Typography>
          </div>
        </Modal>
      </>
    );
  }

  clickCancel = (): void => {
    this.props.history.push("/centre-dashboard/");
  };

  clickSave = (): void => {
    Api.updateEmailInvite(
      this.centreId,
      this.state.groupId,
      this.state.subject,
      this.state.details,
      this.state.emailDataId,
      this.handleSave,
      this.state.emailType
    );

    Api.updateGroupSendDetails(
      this.centreId,
      this.state.groupId,
      this.normalizeDateString(this.state.date),
      this.state.sender,
      this.state.isLastPermitedDateExpires,
      () => {
        // This function expects callback function, but in this case it is not used.
      }
    );
  };

  clickSend = (): void => {
    // if we are already submitting, the button has been double clicked.
    if (!this.state.submitting) {
      // do extra validation here, no sending without data.
      if (!this.validateFields()) {
        return;
      }

      this.setState({
        sendAfterSubmit: true,
        submitting: true
      });

      this.clickSave();
    }
  };

  getRespondentIds = (history: History): string[] => {
    if (!history) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyLocationState: any = history.location.state;

    if (!historyLocationState || !historyLocationState.respondentIds) {
      return [];
    }

    return historyLocationState.respondentIds;
  };

  checkFromURL = (history: History): boolean => {
    if (!history) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyLocationState: any = history.location.state;

    return (
      !!historyLocationState &&
      !!historyLocationState.fromURL &&
      historyLocationState.fromURL === "add-respondents"
    );
  };

  handleDateChange = (date: MaterialUiPickersDate): void => {
    this.setState({ date });
    if (!date) {
      this.setState({ isLastPermitedDateExpires: false });
    }
  };

  handleDispatch = (response: Response): void => {
    if (response.status === HttpStatus.ACCEPTED || HttpStatus.MULTI_STATUS) {
      response
        .json()
        .then(
          (body: {
            successfulRespondentIds: string[];
            conflictedRespondentIds: { respondentId: string; error: string }[];
          }) => {
            // add email dispatch status
            this.props.history.push({
              pathname: "/confirmation/",
              state: {
                emailDispatchStatus: {
                  successfulRespondentCount:
                    body.successfulRespondentIds.length,
                  conflictedRespondentCount:
                    body.conflictedRespondentIds.length,
                  status:
                    response.status === HttpStatus.CONFLICT
                      ? "failure"
                      : "success"
                }
              }
            });
          }
        );
    } else {
      this.setState({
        error: `Error while sending the email to respondent`,
        sendAfterSubmit: false,
        submitting: false
      });
    }
  };

  handleGetGroup(group: Group): void {
    if (!group) {
      this.props.history.push("/centre-dashboard/");
    }

    const defaultSubject =
      "Please take the Cambridge Personal Styles Questionnaire";

    if (group.emailDatas && group.emailDatas.length > 0) {
      const emailData = group.emailDatas.find(
        ed => ed.emailType.name === "invite"
      );
      if (!emailData) throw new Error("No email data exists");

      this.setState({
        emailDataId: emailData.id ? emailData.id : "",
        subject: emailData.subject ? emailData.subject : defaultSubject,
        date: group.lastPermittedTestDate
          ? new Date(group.lastPermittedTestDate)
          : null,
        sender: group.fromName,
        details: emailData.details ? emailData.details : "",
        emailType: emailData.emailType ? emailData.emailType.name : "invite",
        reportViewableByRespondent: group.reportViewableByRespondent,
        isLastPermitedDateExpires: group.isLastPermitedDateExpires
      });
    } else {
      this.setState({
        subject: defaultSubject,
        sender: group.fromName,
        reportViewableByRespondent: group.reportViewableByRespondent
      });
    }
  }

  handleGetGroupDashboard(group: Group): void {
    const respondentIds = getRespondentIdsByStatus(group, "notSent");

    this.setState({
      respondentIds
    });
  }

  handleInputChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    if (!Object.keys(this.state).includes(event.currentTarget.name)) {
      return;
    }

    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<State, "subject" | "sender" | "details" | "emailType">);
  }

  handleSave = (result: { message: string; entity: EmailEntity }): void => {
    if (result.message === "success") {
      if (this.state.sendAfterSubmit) {
        const emailDataId = this.state.emailDataId || result.entity.id;

        Api.triggerEmailDispatch(
          this.centreId,
          this.state.groupId,
          emailDataId,
          this.handleDispatch,
          this.state.respondentIds
        );
      } else {
        this.props.history.push("/centre-dashboard/");
      }
    } else {
      this.setState({
        error: result.message,
        sendAfterSubmit: false,
        submitting: false
      });
    }
  };

  normalizeDateString = (date: Date | null): string => {
    if (!date) {
      return "";
    }

    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  togglePreview(openPreview: boolean): void {
    this.setState({ previewOpen: openPreview });
  }

  validateDate(date: Date): boolean {
    const lastPermittedTestDate = new Date(date);
    const dateNow = new Date(Date.now());

    if (
      date?.toString() === "Invalid Date" ||
      dateNow > lastPermittedTestDate
    ) {
      return false;
    }

    return true;
  }

  validateFields(): boolean {
    let valid = true;

    const fieldErrors: State["fieldErrors"] = {};
    const requiredFields = ["sender", "subject"];

    requiredFields.forEach(requiredField => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (isFieldEmpty((this.state as any)[requiredField])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fieldErrors as any)[requiredField] = "This field is required.";
        valid = false;
      }
    });

    this.setState({ fieldErrors: fieldErrors });

    if (this.state.date) {
      valid = this.validateDate(this.state.date);
    }

    return valid;
  }
}

export default withRouter(CreateInvites);
