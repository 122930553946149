// Core
import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";

// Components
import { Button } from "@cambridgeassessment/cambridge-ui";
import TestUsageSearch from "../../components/testUsageSearch/testUsageSearch";
import TestUsageTable from "../../components/testUsageTable/testUsageTable";
import TestUsageChart from "../../components/testUsageChart/testUsageChart";
import { Typography } from "@material-ui/core";

// Interfaces
import { Centre } from "@cpsq/ui-interfaces";
import { DateRange } from "../../interfaces/date-range";
import { Report } from "../../interfaces/report";
import { ReportMonth } from "../../interfaces/report-month";

// Stylesheets
import "./test-usage.scss";

// Utils
import { useAuth, Session } from "@cpsq/auth-frontend";
import Api from "../../utils/api";

// Vendor
import dayjs from "dayjs";
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  history: History;
  isInternalAdmin: boolean;
}

const ALL_CENTRES = "All centres";
const TestUsage: FC<Props> = (props: Props): ReactElement => {
  const { isInternalAdmin } = useAuth();
  const [activeDateRange, setActiveDateRange] = useState({
    start: "",
    end: ""
  });
  const [activeScope, setActiveScope] = useState([] as Centre[]);
  const [centres, setCentres] = useState([] as Centre[]);
  const [report, setReport] = useState([] as ReportMonth[]);
  const [reports, setReports] = useState([] as Report[]);
  const [years, setYears] = useState([] as number[]);

  useEffect(() => {
    if (isInternalAdmin) {
      setActiveScope([
        {
          centreId: "",
          name: ALL_CENTRES
        }
      ]);
    } else {
      Api.getCentre(Session.getSessionValue("centreId"), (response: Centre) => {
        setActiveScope([
          {
            centreId: response.centreId,
            name: response.name
          }
        ]);
      });
    }
  }, [isInternalAdmin]);

  useEffect(() => {
    const handleGetCentres = (response: Centre[]): void => {
      setCentres(response);
    };

    if (isInternalAdmin) {
      Api.getCentres(handleGetCentres);
    }
  }, [isInternalAdmin]);

  useEffect(() => {
    const handleGetReports = (response: Report[]): void => {
      setReports(response);
    };

    Api.getReports(handleGetReports);
  }, []);

  useEffect(() => {
    const handleGetReport = (response: ReportMonth[]): void => {
      const yearsFromReport = Array.from(
        new Set(
          response.map(item => parseInt(dayjs(item.date).format("YYYY"), 10))
        )
      );

      if (yearsFromReport.length > years.length) {
        const reverseYears = [...yearsFromReport].reverse();
        setYears(reverseYears);
      }

      const isActiveDateRangeNotSet =
        activeDateRange.start === "" && activeDateRange.end === "";
      if (yearsFromReport.length === 1 && isActiveDateRangeNotSet) {
        setActiveDateRange({
          start: dayjs(new Date(yearsFromReport[0], 0, 1)).format("YYYY-MM-DD"),
          end: dayjs(new Date(yearsFromReport[0], 11, 31)).format("YYYY-MM-DD")
        });
      }

      activeDateRange.start === ""
        ? createMultiYearReport(response)
        : createFullYearReport(response, yearsFromReport);
    };

    const handleGetReportError = (): void => {
      setReport([]);
    };

    if (activeScope.length) {
      if (
        (isInternalAdmin ||
          activeScope.some(({ centreId }) => centreId !== "")) &&
        reports.length
      ) {
        const centreIds = activeScope.map(centre => centre.centreId);
        Api.getReport(
          reports[0].id,
          centreIds,
          activeDateRange.start,
          activeDateRange.end,
          handleGetReport,
          handleGetReportError
        );
      }
    }
  }, [activeDateRange, activeScope, isInternalAdmin, reports, years]);

  const createMultiYearReport = (reportResponse: ReportMonth[]): void => {
    setReport(reportResponse);
  };

  const createFullYearReport = (
    reportResponse: ReportMonth[],
    yearsFromReport: number[]
  ): void => {
    const fullYearReport: ReportMonth[] = yearsFromReport
      .map(year => {
        const numberOfMonths =
          year.toString() === dayjs().format("YYYY")
            ? parseInt(dayjs().format("M"))
            : 12;

        return [...Array(numberOfMonths)].map((item, index) => {
          const month = index < 9 ? `0${index + 1}` : index + 1;

          const date = `${year}-${month}-01`;

          return {
            date: date,
            invitesSent: 0,
            testsCompleted: 0,
            testUsageByCentre: []
          };
        });
      })
      .flat();

    reportResponse.forEach(reportMonth => {
      const index = fullYearReport.findIndex(element => {
        return element.date.includes(reportMonth.date.substr(0, 7));
      });

      if (index !== -1) {
        fullYearReport[index].testUsageByCentre = reportMonth.testUsageByCentre;
        fullYearReport[index].invitesSent = reportMonth.invitesSent;
        fullYearReport[index].testsCompleted = reportMonth.testsCompleted;
      }
    });

    setReport(fullYearReport);
  };

  const changeOption = (options: Centre[]): void => {
    setActiveDateRange({ start: "", end: "" });
    if (!options || !options.length) {
      return setActiveScope([
        {
          centreId: "",
          name: ALL_CENTRES
        }
      ]);
    }
    const addedOption = options[options.length - 1];
    if (activeScope.length === 1 && addedOption.name !== ALL_CENTRES) {
      return setActiveScope(options.filter(({ name }) => name !== ALL_CENTRES));
    }
    if (addedOption.name === ALL_CENTRES) {
      return setActiveScope([addedOption]);
    }
    setActiveScope(options);
  };

  const clickYearTab = (year: number, isActive: boolean): void => {
    if (!isActive) {
      setActiveDateRange({
        start: dayjs(new Date(year, 0, 1)).format("YYYY-MM-DD"),
        end: dayjs(new Date(year, 11, 31)).format("YYYY-MM-DD")
      });
    }
  };

  const clickAllTimeTab = (): void => {
    if (activeDateRange.start !== "") {
      setActiveDateRange({ start: "", end: "" });
    }
  };

  const isDateRangeInSameYear = (dateRange: DateRange): boolean => {
    return dayjs(dateRange.start).year() === dayjs(dateRange.end).year();
  };

  const renderAllTimeTabButton = (): JSX.Element => {
    const className = activeDateRange.start === "" ? "primary" : "default";

    return (
      <Button
        color={className}
        key={"custom"}
        data-testid="All time"
        onClick={(): void => clickAllTimeTab()}
      >
        All time
      </Button>
    );
  };

  const renderTabsNavigation = (
    activeDateRangeInput: DateRange,
    yearsInput: number[]
  ): ReactElement => {
    const isMultiYear = yearsInput.length > 1;

    return (
      <div className="tabs-navigation" data-testid="filters">
        <>
          {isMultiYear && renderAllTimeTabButton()}
          {yearsInput.map(year => {
            const isActive =
              isDateRangeInSameYear(activeDateRangeInput) &&
              dayjs(activeDateRangeInput.start).year() === year;

            return (
              <Button
                color={isActive ? "primary" : "default"}
                onClick={(): void => {
                  clickYearTab(year, isActive);
                }}
                key={year}
                data-testid={year.toString()}
              >
                {year.toString()}
              </Button>
            );
          })}
        </>
      </div>
    );
  };

  const scopeTitle = useMemo(
    () => activeScope.map(centre => centre.name).join(", "),
    [activeScope]
  );

  return (
    <div className="test-usage">
      {isInternalAdmin && (
        <TestUsageSearch
          centres={centres}
          activeScope={activeScope}
          // @ts-ignore
          changeOption={changeOption}
        />
      )}
      <div className="hero breakout">
        <Typography
          color="textPrimary"
          variant="subtitle2"
          className="delta bold"
        >
          Test data for:
        </Typography>
        <Typography variant="h3" className="beta bold" data-testid="scope-hero">
          {scopeTitle}
        </Typography>
      </div>
      {report.length !== 0 && (
        <>
          <TestUsageChart report={report} />
          {renderTabsNavigation(activeDateRange, years)}
        </>
      )}
      <TestUsageTable
        activeScopeName={scopeTitle}
        multipleScopes={
          activeScope.length > 1 || activeScope[0]?.name === ALL_CENTRES
        }
        report={report}
        isMultiYear={activeDateRange.start === ""}
      />
    </div>
  );
};

export default withRouter(TestUsage);
