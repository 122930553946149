import { getRedirectUri } from "@cpsq/auth-frontend";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _oauth_: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _gtm_: any;
  }
}

export const OAuthConfig = {
  authority: window._oauth_.SAP_CDC_AUTHORITY as string,
  clientId: window._oauth_.SAP_CDC_CLIENT_ID as string,
  redirectUri: getRedirectUri()
};

export const gtmConfig = {
  gtmId: window._gtm_.GTM_ID as string
};
