// Core
import React, { ReactElement } from "react";

// Vendor
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";

// Components
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Typography } from "@material-ui/core";

// Assets
import paperAirplane from "../../assets/images/paper-airplane.jpg";
import alert from "../../assets/images/alert.svg";

// Stylesheets
import "./confirmation.scss";

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  emailDispatchStatus: {
    successfulRespondentCount: number;
    conflictedRespondentCount: number;
    status: string;
  } | null;
}

export class Confirmation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      emailDispatchStatus: this.getEmailDispatchStatus(this.props.history)
    };
  }

  getEmailDispatchStatus = (
    history: History
  ): {
    successfulRespondentCount: number;
    conflictedRespondentCount: number;
    status: string;
  } | null => {
    if (!history) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyLocationState: any = history.location.state;

    if (!historyLocationState || !historyLocationState.emailDispatchStatus) {
      return null;
    }

    return historyLocationState.emailDispatchStatus;
  };

  getDisplayMessage = (): string => {
    let displayMessage = "";
    if (this.state.emailDispatchStatus) {
      if (this.state.emailDispatchStatus.successfulRespondentCount) {
        displayMessage += `Email sent to ${
          this.state.emailDispatchStatus.successfulRespondentCount
        } respondent${
          this.state.emailDispatchStatus.successfulRespondentCount === 1
            ? "."
            : "s. "
        }`;
      }
      if (this.state.emailDispatchStatus.conflictedRespondentCount) {
        displayMessage += `Email not sent to ${
          this.state.emailDispatchStatus.conflictedRespondentCount
        } respondent${
          this.state.emailDispatchStatus.conflictedRespondentCount === 1
            ? "."
            : "s. "
        }`;
      }
    }
    return displayMessage;
  };

  getHeading = (): string => {
    if (this.state.emailDispatchStatus) {
      return this.state.emailDispatchStatus.status === "success"
        ? "Email sent"
        : "Email not sent";
    } else return "";
  };
  render(): ReactElement {
    return (
      <section>
        <Typography
          variant="h3"
          gutterBottom
          className="section-heading beta bold"
        >
          {this.getHeading()}
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className="confirmation-text"
          id="displayMessage"
        >
          {this.getDisplayMessage()}
        </Typography>
        <img
          src={
            // as of 27/04/2020, SonarQube *still* doesn't recognise the safe-navigation operator  ?.  even though the Sonarlint plug-in does
            //this.state.emailDispatchStatus?.status === "success"
            // as of 13/05/2021, SonarQube *still* doesn't recocgnise it
            this.state.emailDispatchStatus &&
            this.state.emailDispatchStatus.status === "success"
              ? paperAirplane
              : alert
          }
          alt="Logo"
          className="confirmation-image"
        />
        <Button
          color="primary"
          key="submit"
          onClick={this.clickFinished}
          data-testid="Back to Dashboard"
        >
          Back to Dashboard
        </Button>
      </section>
    );
  }

  clickFinished = (): void => {
    this.props.history.push("/centre-dashboard/");
  };
}

export default withRouter(Confirmation);
