// Core
import React, { ReactElement, useEffect } from "react";

// Components
import { StandardLayout } from "@cpsq/ui-components";
import { Button } from "@cambridgeassessment/cambridge-ui";
import { WelcomeInfo } from "@cpsq/ui-components";
import { Typography } from "@material-ui/core";

// Stylesheets
import "./home.scss";

// Utils
import { useAuth } from "@cpsq/auth-frontend";

// Vendor
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  history: History;
}

const Home = (props: Props): ReactElement => {
  const { isAuthenticated, signIn } = useAuth();

  useEffect(() => {
    document.body.className = "welcome";

    if (isAuthenticated) {
      props.history.push("/dashboard/");
    }

    return (): void => {
      document.body.className = "";
    };
  });

  const Title = (): JSX.Element => {
    return (
      <Typography variant="h4" gutterBottom className="bold">
        <span className="bold">
          Welcome to The Cambridge Personal Styles Questionnaire
        </span>
      </Typography>
    );
  };

  const HomeContactUs = (generalInfo: JSX.Element): JSX.Element => {
    return (
      <>
        {generalInfo}
        <p className="gamma">To continue, please log in to access CPSQ.</p>
        <p className="gamma">
          To find out more about how CPSQ can help your organisation,{" "}
          <a
            href="https://www.admissionstesting.org/for-institutions/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>
        </p>
        <Button color="primary" onClick={signIn} data-testid="Go to Log In">
          Log in
        </Button>
      </>
    );
  };

  return (
    <StandardLayout>
      <div className="welcome">
        <div className="welcome-card">
          <WelcomeInfo title={Title()} renderWelcomeInfo={HomeContactUs} />
        </div>
      </div>
    </StandardLayout>
  );
};

export default withRouter(Home);
