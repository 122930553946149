// Core
import React, { ReactElement } from "react";

// Components
import { GroupsList } from "@cpsq/ui-components";
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Typography } from "@material-ui/core";

// Interfaces
import { Group } from "@cpsq/ui-interfaces";

// Stylesheets
import "./centreDashboard.scss";
import "./centreDashboard-responsive.scss";

// Utils
import { rerouteWithState } from "@cpsq/common-utils";
import Api from "../../utils/api";

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Session } from "@cpsq/auth-frontend";

interface Props extends RouteComponentProps {
  history: History;
}

interface State {
  activeGroupFilter: string;
  activeGroupSortBy: string;
  filteredGroups: Group[];
  groups: Group[];
  loaded: boolean;
}
export class CentreDashboard extends React.Component<Props, State> {
  centreId = Session.getSessionValue("centreId");
  searchInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.searchInput = React.createRef();

    this.state = {
      activeGroupFilter: "",
      activeGroupSortBy: "",
      filteredGroups: [],
      groups: [],
      loaded: false
    };

    this.clickGroup = this.clickGroup.bind(this);
  }

  componentDidMount(): void {
    document.body.className = "centre-dashboard";
  }

  componentWillUnmount(): void {
    document.body.className = "";
  }

  render(): ReactElement {
    return (
      <section className="centre-dashboard">
        <header className="breakout">
          <div className="column-one">
            <Typography variant="h3" className="section-heading beta bold">
              Your groups
            </Typography>
            <Typography
              variant="subtitle2"
              className="section-sub-heading delta bold"
            >
              Your groups are where you can invite and manage your candidates.
            </Typography>
            <form className="groups-filter">
              <input
                type="text"
                placeholder="Search for groups"
                ref={this.searchInput}
              />
              <FontAwesomeIcon icon="search" />
            </form>
          </div>
          <div className="column-two">
            <Button
              color="primary"
              startIcon={<FontAwesomeIcon icon="plus" />}
              onClick={this.clickNewGroup}
              data-testid="New group"
            >
              New group
            </Button>
          </div>
        </header>
        <GroupsList
          inputRef={this.searchInput}
          centreId={this.centreId}
          getGroups={Api.getGroups}
          clickGroup={this.clickGroup}
        />
      </section>
    );
  }

  clickGroup = (id: string): void => {
    Api.getGroupDashboard(
      this.centreId,
      id,
      this.clickGroupCallback.bind(this, id)
    );
  };

  clickGroupCallback = (id: string, group: Group): void => {
    if (group) {
      rerouteWithState(this.props.history, `/group-dashboard/?groupId=${id}`, {
        group
      });
    } else {
      this.props.history.push(`/group-dashboard/?groupId=${id}`);
    }
  };

  clickNewGroup = (): void => {
    this.props.history.push("/new-group/");
  };

  getGroup = (id: string, groupsState: Group[]): Group | undefined => {
    return groupsState.find(group => group.id === id);
  };
}

export default withRouter(CentreDashboard);
