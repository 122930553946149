// Core
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  forwardRef
} from "react";

// Components
import { LoadingSpinner } from "@cpsq/ui-components";
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Typography, Tooltip } from "@material-ui/core";

// Stylesheets
import "./apiKey.scss";

// Utils
import { Session } from "@cpsq/auth-frontend";
import Api from "../../utils/api";

// Vendor
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";

const StyledTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.15)",
    enterTouchDelay: 0
  },
  arrow: {
    color: "white"
  }
})(Tooltip);

const InfoIcon = forwardRef((props, ref) => (
  <FontAwesomeIcon icon="question-circle" forwardedRef={ref} {...props} />
));
InfoIcon.displayName = "InfoIcon";

const ApiKey: FC = () => {
  const [centreId, setCentreId] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const history = useHistory();

  const apiKeyText = useMemo(
    () => (apiKey && (visible ? apiKey : apiKey.replace(/./g, "\u2022"))) || "",
    [apiKey, visible]
  );

  const onCopy = useCallback((): void => {
    if (apiKey) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(apiKey).then(
          () => {
            toastr.success("Text copied");
          },
          () => {
            toastr.error("Could not copy text");
          }
        );
        return;
      }

      const textArea = document.createElement("textarea");
      textArea.value = apiKey;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          toastr.success("Text copied");
        } else {
          toastr.error("Could not copy text");
        }
      } catch (copyError) {
        toastr.error("Could not copy text");
      }

      document.body.removeChild(textArea);
    }
  }, [apiKey]);

  const onToggleVisible = useCallback((): void => {
    setVisible(v => !v);
  }, [setVisible]);

  const handleGetApiKey = useCallback(
    (response: { apiKey: string }): void => {
      setApiKey(response.apiKey);
      setLoading(false);
    },
    [setApiKey, setLoading]
  );

  const handleGetApiKeyError = useCallback(
    (apiKeyGetError: Error, response: Response): void => {
      if (response.status === 404) {
        setApiKey(null);
      } else {
        setError(apiKeyGetError.message);
      }
      setLoading(false);
    },
    [setApiKey, setError, setLoading]
  );

  const handleGenerateApiKey = useCallback(
    (response: { message: string; entity: { apiKey: string } }): void => {
      if (response.message === "success") {
        setApiKey(response.entity.apiKey);
      } else {
        setError(response.message);
      }
    },
    [setApiKey, setError]
  );

  const handleDeleteApiKey = useCallback(
    (response: { message: string }): void => {
      if (response.message === "success") {
        setApiKey(null);
      } else {
        setError(response.message);
      }
    },
    [setApiKey, setError]
  );

  const onGenerate = useCallback((): void => {
    if (centreId) {
      Api.generateApiKey(centreId, handleGenerateApiKey);
    }
  }, [centreId, handleGenerateApiKey]);

  const onRevoke = useCallback((): void => {
    if (centreId) {
      Api.deleteApiKey(centreId, handleDeleteApiKey);
    }
  }, [centreId, handleDeleteApiKey]);

  const onViewApiSpecification = useCallback((): void => {
    history.push("/api-key/specification");
  }, [history]);

  useEffect(() => {
    setCentreId(Session.getSessionValue("centreId") || null);
  }, [setCentreId]);

  useEffect(() => {
    if (centreId) {
      Api.getApiKey(centreId, handleGetApiKey, handleGetApiKeyError);
    }
  }, [centreId, handleGetApiKey, handleGetApiKeyError]);

  useEffect(() => {
    if (error) {
      toastr.error(error);
      setError(null);
    }
  }, [error, setError]);

  if (loading) return <LoadingSpinner loadingText="Loading API key..." />;

  return (
    <div className="container">
      <div className="api-key-heading">
        <Typography variant="h4" className="section-heading beta bold">
          API key
        </Typography>

        <StyledTooltip
          arrow
          title={
            <Typography variant="body2" className="infoText">
              Your API key can be used to authenticate you when using the REST
              API. To use the API key, add the following header to all REST API
              calls: &apos;X-Api-Key: {"<"}YOUR_API_KEY{">"}&apos;
            </Typography>
          }
        >
          <InfoIcon />
        </StyledTooltip>
      </div>

      {!apiKey ? (
        <Button
          color="primary"
          onClick={onGenerate}
          className="button api-key-action-btn"
          data-testid="generate"
        >
          Generate API key
        </Button>
      ) : (
        <>
          <div className="api-key-wrapper">
            <div className="api-key-input-wrapper">
              <input
                className="api-key-input"
                data-testid="api-key-input"
                readOnly
                autoComplete="off"
                value={apiKeyText}
              />
              <Button
                variant="text"
                size="small"
                color="default"
                className="visibility-button"
                onClick={onToggleVisible}
                data-testid="toggle-visible"
              >
                <FontAwesomeIcon icon="eye" />
              </Button>
            </div>
            <StyledTooltip disableFocusListener arrow title="Copy to clipboard">
              <span>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={onCopy}
                  data-testid="copy"
                >
                  <FontAwesomeIcon icon="copy" />
                </Button>
              </span>
            </StyledTooltip>

            <StyledTooltip disableFocusListener arrow title="Regenerate">
              <span>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={onGenerate}
                  data-testid="regenerate"
                >
                  <FontAwesomeIcon icon="redo" />
                </Button>
              </span>
            </StyledTooltip>
          </div>
          <Button
            color="default"
            onClick={onRevoke}
            className="button api-key-action-btn"
            data-testid="revoke"
          >
            Revoke API key
          </Button>

          <Button
            variant="text"
            size="small"
            className="specification-button"
            onClick={onViewApiSpecification}
            data-testid="specification"
          >
            View API Specification
          </Button>
        </>
      )}
    </div>
  );
};

export default withRouter(ApiKey);
