const isDateInvalid = (date: Date): boolean => {
  return !date || (date && date.toDateString() === "Invalid Date");
};

export const getHumanDateString = (date: Date): string => {
  if (isDateInvalid(date)) {
    return "Invalid date";
  }

  return (
    date.toLocaleDateString("en-GB", { day: "numeric" }) +
    " " +
    date.toLocaleDateString("en-GB", { month: "long" }) +
    " " +
    date.toLocaleDateString("en-GB", { year: "numeric" })
  );
};

export const getHumanDateTimeString = (date: Date): string => {
  if (isDateInvalid(date)) {
    return "Invalid date";
  }

  // we use en-US format as this adds AM/PM, which clearer for morning times.
  return getHumanDateString(date) + " " + date.toLocaleTimeString("en-US");
};
