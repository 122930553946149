// Core
import React, { ReactElement } from "react";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */

// Utils
import { getViewportSize } from "@cpsq/common-utils";

// Stylesheets
import "./respondentResultsTable.scss";

// Vendor
import { createTheme, MuiThemeProvider, Theme } from "@material-ui/core/styles";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  csvFilename: string;
}

interface State {
  columns: string[];
}

type ViewportType = "mobile" | "tablet" | "desktop";

const getMuiTheme = (viewportSize: ViewportType): Theme =>
  createTheme({
    overrides: {
      MuiTableRow: {
        root: {
          "&:nth-child(even)": { backgroundColor: "#f2f2f2" }
        }
      },
      MuiTableHead: {
        root: {}
      },
      MuiTableCell: {
        body: {
          color: "black",
          fontFamily: "Source Sans Pro",
          fontSize: "16px",
          height: "60px",
          padding: "20px"
        },
        head: {
          backgroundColor: "#f9f9f9 !important",
          color: "black",
          fontFamily: "Source Sans Pro",
          fontSize: "16px",
          fontWeight: "bold"
        }
      },
      ...(viewportSize === "mobile" && {
        MuiPopover: {
          paper: {
            maxWidth: "100% !important",
            height: "100%",
            "& > div": {
              height: "100%",
              backgroundColor: "inherit"
            }
          }
        },
        MUIDataTableFilter: {
          header: {
            marginLeft: "10px"
          }
        },
        MuiMenu: {
          paper: {
            height: "auto"
          }
        },
        MuiGrid: {
          container: {
            height: "100%",
            width: "calc(100% + 16px) !important"
          },
          root: {
            margin: "0 !important"
          }
        },
        MuiSelect: {
          root: {
            marginTop: "36px"
          }
        }
      })
    }
  });
class RespondentResultsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let keys: string[] = [];
    if (this.props.data && this.props.data.length > 0) {
      keys = Object.keys(this.props.data[0]);
    }

    this.state = {
      columns: keys
    };
  }

  componentDidUpdate(prevProps: Props): void | null {
    if (prevProps.data === this.props.data) return null;

    if (!this.props.data || this.props.data.length === 0)
      return this.setState({ columns: [] });

    const keys: string[] = Object.keys(this.props.data[0]);
    this.setState({
      columns: keys
    });
  }

  render(): ReactElement | null {
    if (!this.props.data) {
      return null;
    }
    const options: MUIDataTableOptions = {
      download: true,
      filter: true,
      filterType: "dropdown",
      pagination: true,
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50],
      print: false,
      responsive: "standard",
      search: false,
      selectableRows: "none",
      selectableRowsOnClick: false,
      viewColumns: true,
      elevation: 0,
      downloadOptions: {
        filename: `${this.props.csvFilename}.csv`,
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      fixedHeader: false,
      fixedSelectColumn: true
    };

    return (
      <div className="respondent-results-table">
        <MuiThemeProvider
          theme={getMuiTheme(
            getViewportSize(window.innerWidth) as ViewportType
          )}
        >
          <MUIDataTable
            data={this.props.data}
            columns={this.state.columns}
            options={options}
            title=""
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default RespondentResultsTable;
